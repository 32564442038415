import React, {useState, useEffect} from 'react'
import {Box, Typography, Stack} from '@mui/material'
import {isEmpty} from 'lodash'

import {useFees} from 'service/hook'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import {CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets'

const MdlNewFee = (p) => {
  const min = 0
  const max = 100
  const {fee, onCloseModal} = p
  const {status, fees, addFee, updateFee, deleteFee} = useFees()

  const [value, setValue] = useState({
    defaultConsignmentFee: '',
    feeName: '',
    feeRate: '',
  })
  const [errorMessage, setErrorMessage] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const defaultFee = (!isEmpty(fees) && fees?.filter((f) => f?.name?.toLowerCase() === 'default')) || []

  useEffect(() => {
    if (status === 'success') {
      setValue({
        defaultConsignmentFee: !isEmpty(defaultFee) ? defaultFee[0].value : '',
        feeName: isEmpty(defaultFee) ? 'Default' : fee?.name || '',
        feeRate: fee?.value || '',
      })
    }
  }, [status, fees])

  const handleFeeSettingsData = (e) => {
    const name = e.currentTarget.name
    let val = e.currentTarget.value

    if (name === 'feeRate') {
      val = Math.max(min, Math.min(max, Number(val))) || ''
    }

    setValue({
      ...value,
      [name]: val,
    })
  }

  const submit = (e) => {
    setIsSubmitting(true)

    let formError = null
    if (!value?.feeName || !value?.feeRate) {
      formError = 'is required'
    } else if (value?.feeName?.toLowerCase() === 'default' && !isEmpty(defaultFee)) {
      formError = '- Default fee is already specificied. Please choose another fee name.'
    } else {
      formError = null
    }

    setErrorMessage(formError)

    if (!formError) {
      addFee({name: value?.feeName, value: value.feeRate})
      onCloseModal()
    }
  }

  const hasError = isSubmitting && errorMessage

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            {fee ? 'Update Fee' : 'New Fee'}
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2}>
        <Stack direction="column" px={2}>
          <FieldGroup
            sLabel="Fee Name"
            disabled={isEmpty(defaultFee)}
            maxLength={15}
            hasError={hasError && !value?.feeName}
            errorMessage={`Fee Name ${errorMessage}`}
            name="feeName"
            type="text"
            placeholder="Fee Name"
            onChange={handleFeeSettingsData}
            value={value.feeName}
          />
          <FieldGroup
            sLabel="Fee Rate"
            minLength={1}
            maxLength={100}
            hasError={hasError && (!value?.feeName || errorMessage?.includes('Default'))}
            errorMessage={`Fee Rate ${errorMessage}`}
            name="feeRate"
            type="number"
            onKeyPress={(e) => onKeyPress(e, 'e')}
            placeholder="Fee Rate"
            onChange={handleFeeSettingsData}
            value={value.feeRate}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={1}
          mt={5}
          borderTop="1px solid #eee"
          spacing={2}
        >
          {fee ? (
            <>
              <CopytStyledButton
                variant="contained"
                color="error"
                onClick={() => {
                  deleteFee(fee?.id)
                  onCloseModal()
                }}
              >
                Delete
              </CopytStyledButton>
              <CopytStyledButton
                variant="contained"
                color="primary"
                onClick={() => {
                  updateFee({name: value.feeName, id: fee.id, fee: value?.feeRate})
                  onCloseModal()
                }}
              >
                Update
              </CopytStyledButton>
            </>
          ) : (
            <CopytStyledButton variant="contained" color="primary" onClick={submit}>
              Add
            </CopytStyledButton>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlNewFee

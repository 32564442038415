import React, {memo, useCallback, useContext, useEffect, useState} from 'react'
import clx from 'classnames'
import {DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF} from '@mui/x-data-grid'
import {Box, Checkbox, Chip, Tooltip} from '@mui/material'
import {HourglassBottom} from '@mui/icons-material'
import {debounce, orderBy, isEmpty, sumBy, minBy} from 'lodash'

import DetailView from 'components/DetailView'
import {UnlistedTableFilterMenu} from 'assets/data/options/inventory_filter_menu'
import {useNavigator} from 'service/hook/use_navigator'
import {useGlobalStore} from 'provider/global_store/hook'
import {ConsignStatus, ModalType, RedirectAction} from 'enums'
import {useUser} from 'service/hook'
import {capitalizeText} from 'util/string_utils'
import {formatTo} from 'util/date_utils'
import {isConsigned, isProductHasListingRecord} from 'util/model/product'
import {getCurrency} from 'util/model/setting'
import {CopytTooltip} from 'views_v2/lib/snippets'
import {ConsignorProfile} from 'views_v2/modules/Consignments/component/consignment_application/ConsignorProfile'
import {ReactComponent as ConsignedBannerSvg} from '../../../../../../assets/img/consigned-banner_v2.svg'
import {CustomCheckbox, CustomFooter, EmptyInventory, InventoryToolbar} from '../../../snippets'
import {SearchContext, TabPanel} from '../../../index'
import {generateExportData} from '../../../hooks/util'

export const sortedData = (items, sortModel) => {
  return orderBy(
    items,
    sortModel.length ? [(row) => row[sortModel[0].field]] : [],
    sortModel.length ? [sortModel[0].sort] : ['desc'],
  )
}

const UnlistedTable = (p) => {
  const {
    disableBtn,
    changeCheckbox,
    handleChange,
    items,
    routeChange,
    setModalType,
    isFetching,
    queries,
    showWithdraw,
    listSelections,
    currentPage,
    setCurrentPage,
    isCardList = false,
    onNavigateUrl,
    isLoading,
    metricsQuery,
    isDisabled,
  } = p

  const {user} = useUser()
  const {isEnterprise} = useGlobalStore()
  const search = useContext(SearchContext)
  const currency = getCurrency()

  const isMobile = window.innerWidth <= 900

  const onSearch = useCallback(
    debounce((searchKey) => {
      search.setUnlistedSearchText(searchKey), 1000
    }),
    [],
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}

    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }
    search.setUnlistedSearchText(params[RedirectAction.SEARCH]?.split(' ').join('+'))
    searchParams.delete(RedirectAction.SEARCH)

    const newUrl = window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState({}, '', newUrl)
  }, [])

  const SearchToolBar = () => {
    if (!isCardList) {
      return (
        <InventoryToolbar
          {...{disableBtn, queries, showWithdraw}}
          showDelete
          filterMenu={UnlistedTableFilterMenu}
          showListAll={!isMobile && !isEmpty(listSelections)}
          showAverage={!isMobile}
          showBulkEdit={!isEmpty(listSelections)}
          average={{
            cost: metricsQuery?.data?.averageCost || 0,
            price: metricsQuery?.data?.averagePrice || 0,
          }}
          search={{
            disabled: isFetching,
            value: search?.unlistedSearchText,
            onSearch: onSearch,
          }}
          action={{
            onNavigateUrl: onNavigateUrl,
            onDeleteAll: () => setModalType(ModalType.DELETE_ALL),
            onBulkEdit: () => setModalType(ModalType.BULK_EDIT),
            onListMultiple: () => setModalType(ModalType.LIST_ALL_PLATFORM),
            onSaveChanges: p?.onSaveChanges,
          }}
          allItems={generateExportData(p?.allData, TabPanel.UNLISTED)}
          selectedTab="Unlisted"
        />
      )
    }
    return null
  }

  const columns = [
    {
      field: 'assets',
      headerName: '',
      flex: isEnterprise ? 1 : 0.7,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const {assets, consign} = params.row || {}
        const hasConsignBanner =
          (isEnterprise && ['approved', 'withdraw_pending'].includes(consign?.status.toLowerCase())) ||
          (isConsigned(params.row) &&
            ![ConsignStatus.WITHDRAW_APPROVED, ConsignStatus.REJECTED, ConsignStatus.PENDING].includes(
              consign?.status.toLowerCase(),
            ))
        const image = isEmpty(assets) ? null : minBy(assets, (asset) => Math.abs(asset.order - 1))

        return (
          <div className={clx(isEmpty(assets) && hasConsignBanner && 'no-image', 'img-overlay-wrap')}>
            {hasConsignBanner && <ConsignedBannerSvg />}
            {!isEmpty(assets) && <img src={image?.asset?.url} />}
          </div>
        )
      },
    },
    {
      field: 'title',
      headerName: 'Name',
      headerAlign: 'center',
      align: 'center',
      flex: isEnterprise ? 1 : 2,
      renderCell: (params) => {
        const title = params?.value
        return (
          <Tooltip {...{title}}>
            <span>{title}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'sku',
      headerName: 'SKU/Style ID',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: isMobile,
    },
    {
      field: 'size',
      headerName: 'Size',
      headerAlign: 'center',
      align: 'center',
      flex: 0.7,
    },
    {
      field: 'condition',
      headerName: 'Condition',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Buy Price',
      headerAlign: 'center',
      align: 'center',
      flex: isEnterprise ? 0.8 : 1,
      renderCell: (params) => {
        const {consign} = params?.row || {}
        if (consign && isEnterprise) return ''
        else return `${currency.format(params?.value)}`
      },
    },
    {
      field: 'desiredReturn',
      headerName: 'List Price',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => `${currency.format(params?.value)}`,
    },
    {
      field: 'internalSku',
      headerName: 'Internal SKU',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: !isEnterprise || isMobile,
      renderCell: (params) => {
        const title = params?.value
        return (
          <Tooltip {...{title}}>
            <span>{title}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: !isEnterprise || isMobile,
      renderCell: (params) => {
        return (
          <Tooltip>
            <span>{params.row.location}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'subLocation',
      headerName: 'Sub Location',
      headerAlign: 'center',
      align: 'center',
      hide: !isEnterprise || isMobile,
      flex: 1,
      renderCell: (params) => {
        const title = params?.value
        return (
          <Tooltip {...{title}}>
            <span>{title}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'acquiredDate',
      headerName: 'Acquired Date',
      headerAlign: 'center',
      align: 'center',
      flex: isEnterprise ? 0.8 : 1,
      hide: isMobile,
      renderCell: (params) => {
        const {updateAt, acquiredDate} = params?.row || {}
        const approvalDate = params?.row?.consign?.approvalDate
        const acquired =
          isEnterprise && isConsigned(params?.row)
            ? `${formatTo(approvalDate, 'YYYY-MM-DD')}`
            : acquiredDate
        return <span>{acquired}</span>
      },
    },
    {
      headerName: 'Shipping Status',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: isMobile,
      renderCell: (params) => {
        const {shipments} = params?.row || {}
        if (!shipments?.length) return

        shipments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        const [{shipment}] = shipments

        let label = shipment?.status

        switch (shipment?.type) {
          case 'PURCHASE': {
            switch (shipment?.status) {
              case 'TRANSIT': {
                label = 'RECEIVED'
                break
              }
            }
            break
          }
          case 'CONSIGNMENT': {
            switch (shipment?.status) {
              case 'TRANSIT': {
                if (!isEnterprise) break
                label = 'RECEIVED'
                break
              }
            }
            break
          }
        }

        return (
          <CopytTooltip title={label}>
            <Chip label={capitalizeText(label)} size="small" />
          </CopytTooltip>
        )
      },
    },
    {
      field: 'owner',
      headerName: 'Owner',
      headerAlign: 'center',
      flex: 2,
      hide: !isEnterprise || isMobile,
      renderCell: (params) => {
        const hasConsignBanner = isConsigned(params.row)
        const email = hasConsignBanner ? params?.row?.consign?.consignor?.email : user?.company?.name

        if (hasConsignBanner) {
          return (
            <ConsignorProfile
              consignorEmail={params?.row?.consign?.consignor?.email}
              consignorId={params?.row?.consign?.consignor?.id}
            />
          )
        }

        return (
          <Tooltip title={email}>
            <Box component="span">{email}</Box>
          </Tooltip>
        )
      },
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.2,
    },
  ]

  const modifiedCheckboxColumn = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: (params) => {
      if (params?.row.onQueue) {
        return (
          <CopytTooltip title="Listing in progress">
            <HourglassBottom />
          </CopytTooltip>
        )
      } else if (isDisabled) {
        return <Checkbox disabled />
      } else {
        return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell ? (
          GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)
        ) : (
          <Checkbox />
        )
      }
    },
    flex: 0.2,
  }

  const newColumns = columns.map((col) =>
    col.type === 'checkboxSelection' ? modifiedCheckboxColumn : col,
  )

  const CustomNoRowsOverlay = () => {
    return (
      <EmptyInventory
        {...{disableBtn, handleChange, routeChange}}
        type="unlisted"
        tabSelection="unlisted"
        TabHandler={() => null}
      />
    )
  }

  const rows = isCardList ? p?.cardItem : items?.data

  return (
    <DetailView.Panel style={{height: isCardList ? '500px' : '100%'}}>
      <Box className="inventory-container unlisted-table">
        <DataGrid
          overflow="visible"
          rows={rows}
          columns={newColumns}
          loading={isLoading || isFetching}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids)
            const selectedRowData = p?.allData?.filter((row) => selectedIDs.has(row?.id))
            changeCheckbox(selectedRowData)
          }}
          selectionModel={Array.isArray(listSelections) && listSelections?.map((s) => s?.id)}
          onCellClick={(param) => {
            if (param?.field !== '__check__') {
              const {id} = param?.row
              routeChange(`/admin/inventory/viewItem/${id}`)
            }
          }}
          checkboxSelection
          disableColumnSelector
          disableColumnMenu
          disableDensitySelector
          disableVirtualization
          isRowSelectable={(params) => {
            const row = params.row
            return !row.onQueue || (row?.sku && !isProductHasListingRecord(row))
          }}
          components={{
            Toolbar: SearchToolBar,
            BaseCheckbox: CustomCheckbox,
            NoRowsOverlay: CustomNoRowsOverlay,
            Footer: CustomFooter,
          }}
          componentsProps={{
            footer: {
              total:
                (isCardList
                  ? sumBy(rows, (product) => parseFloat(product?.desiredReturn))
                  : metricsQuery?.data?.totalListPrice) || 0,
              label: 'List Price',
            },
          }}
          getRowId={(row) => row.id}
          rowCount={(isCardList ? rows?.length : items?.total) || 0}
          onPageChange={(newPage) => {
            if (!isLoading && !isFetching && !isCardList) {
              setCurrentPage(newPage)
            }
          }}
          page={currentPage}
          paginationMode="server"
          pagination
          disablePagination={isLoading || isFetching || isDisabled}
          disableSelectionOnClick={isDisabled}
          rowsPerPageOptions={[100]}
          onSortModelChange={(newSort) => p?.onSaveChanges({sort: newSort[0]})}
          sx={{
            '& .MuiDataGrid-main': {
              bgcolor: 'white',
            },
            '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footer': {
              bgcolor: isCardList ? '#ffbeeb36' : '#faebf5',
            },
            '& .MuiTablePagination-actions': {
              button: {
                opacity: isLoading || isFetching ? 0.3 : 1,
                cursor: isLoading || isFetching ? 'no-drop' : 'pointer',
                pointerEvents: isLoading || isFetching ? 'none' : '',
              },
              '& .Mui-disabled': {
                opacity: 0.3,
              },
            },
          }}
        />
      </Box>
    </DetailView.Panel>
  )
}

export default memo(UnlistedTable)

import moment from 'moment'
export const getTime = (ms) => {
  // todo: use timer
  var min = Math.floor(ms / (1000 * 60))
  var hour = Math.floor(ms / (1000 * 60 * 60))
  var day = Math.floor(ms / (1000 * 60 * 60 * 24))

  if (day >= 1) {
    return day + " day's"
  } else if (hour >= 1) {
    return hour + " hour's"
  } else {
    return min + " min's"
  }
}

export const listingDuration = (time) => {
  if (!time) {
    return ''
  }

  const ms = moment(new Date()).diff(time)

  return getTime(ms)
}

export const soldDuration = (listtime, soldtime) => {
  if (!listtime || !soldtime) {
    return ''
  }

  const ms = moment(soldtime).diff(listtime)

  return getTime(ms)
}

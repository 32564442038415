import React from 'react'
import {styled} from '@mui/material/styles'
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip'
import zIndex from '@mui/material/styles/zIndex'

const CopytTooltip = styled(({className, arrow = true, backgroundColor, color, children, ...props}) => (
  <Tooltip {...props} arrow={arrow} classes={{popper: className}}>
    {children}
  </Tooltip>
))(({theme, backgroundColor, color}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor || 'var(--primary)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.9em',
    maxWidth: 200,
    backgroundColor: backgroundColor || 'var(--primary)',
    color: color || 'white',
  },
  [`&.${tooltipClasses.popper}`]: {
    zIndex: '9999999 !important',
  },
}))

export default CopytTooltip

export default class AddressComponents {
  static EMPTY = new AddressComponents()

  address = null
  city = null
  postal = null
  zipcode = null

  constructor(props) {
    Object.assign(this)
  }

  static parse(props) {
    const address = new AddressComponents()
    const keys = Object.keys(address)

    if (props) {
      keys.forEach((key) => {
        const propsKey = props[key] || props[key?.toLowerCase()]
        address[key] = propsKey || null
      })
    }
    return address
  }

  fullAddress() {
    return [this.address, this.city, this.state, this.zipcode].filter(x => !!x).join(', ') || ''
  }
}

import React, {useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import DetailView from 'components/DetailView'
import {chain, find, get, has, isEmpty, isNaN, keyBy, some} from 'lodash'
import axios from 'axios'
import {captureException} from '@sentry/react'
import {Box, Grid} from '@mui/material'
import {
  Cancel as CancelIcon,
  FilterListOff as FilterListOffIcon,
  Save as SaveIcon,
} from '@mui/icons-material'
// TODO: Update Modal and use CopytModal instead of this reactStrap
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'

import {apiConfig} from 'config'
import {ListingPlatformStatus} from 'constant/listing_platform_status'
import {ToastType} from 'enums'
import {useGlobalStore} from 'provider/global_store/hook'
import {useListing} from 'service/hook/use_listing'
import {usePlatform} from 'service/hook/use_platform'
import {useCredential, useStoreSettings} from 'service/hook'
import {createRequestOptions} from 'util/network'
import {getPredefinedObject, _validateFormFields} from 'util/string_utils'
import {isConsigned} from 'util/model/product'
import {CopytToolbar} from 'views_v2/lib/snippets'
import {CustomButton} from 'views_v2/modules/Inventory/snippets/headers'
import {
  Overview,
  ConsignorInformation,
  InventoryImages,
  InventoryPricing,
  InventoryTracking,
  InventoryPlatforms,
} from 'views_v2/modules/Inventory/components/cards'
import {ListingInventoryDetails} from 'views_v2/modules/Listings'
import getMerchant from 'views_v2/modules/Listings/middleware/getMerchant'
import InventoryToast from 'views_v2/modules/Inventory/components/InventoryToast'
import DateUtils from 'util/date_utils'
import {ReactComponent as ListingFailureSvg} from '../../assets/img/ListingFailure.svg'
import {ReactComponent as AlertSvg} from '../../assets/img/Alert Circle.svg'
import Paywall from '../paywall/Paywall'
import {useViewInventory} from '../inventory/view/hook'
import {useOnBack} from '../../hooks/use_on_back'

const EditListing = () => {
  const history = useHistory()
  const {id} = useParams()
  const {listingData, listingLoading} = useListing(id)
  const product = listingData?.product || {}
  const {getAccessTokenSilently} = useAuth0()
  const [formFields, setFormFields] = useState({
    quantity: 1,
    price: product?.price,
  })
  const [errorMessage, setErrorMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [hideDelist, setHideDelist] = useState(false)
  const [failedAdditionsModal, setFailedAdditionsModal] = useState([])
  const [failedDeletionsModal, setFailedDeletionsModal] = useState([])
  const [deletionModal, setDeletionModal] = useState(false)
  const [dialogRoute, setDialogRoute] = useState('/admin/listings/viewListings')
  const [premiumModal, setPremiumModal] = useState(false)
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const [toastContent, setToastContent] = useState(null)
  const [cloverStoreLocations, setCloverStoreLocations] = useState([])
  const {isEnterprise} = useGlobalStore()
  const {credential} = useCredential()
  const {locations: settingsStoreLocations} = useStoreSettings()
  const {platforms} = usePlatform({type: 'market', status: 'member', take: 50})

  const {refetchInventory} = useViewInventory()
  const [desiredReturn, setDesiredReturn] = useState()
  const [checkedState, setCheckedState] = useState(
    platforms.map((platform) => ({
      id: platform.id,
      checked: false,
    })),
  )

  const platformOptions = useMemo(() => {
    return platforms
      .filter((platform) => !['paypal', 'checkbook'].includes(platform?.name?.toLowerCase()))
      .map((platform) => ({
        label: platform.name,
        id: platform.id,
        value: platform.id,
        hasCred: platform.credentials.length > 0,
      }))
  }, [platforms])

  const platformSelection = useMemo(() => {
    return chain(listingData?.listingPlatforms)
      .filter((lp) => lp.status !== 'pending')
      .map((lp) => lp.platform.id)
      .value()
  }, [listingData])

  useEffect(() => {
    setDesiredReturn(listingData?.price)
    setFormFields({...formFields, price: product?.price})

    //find listing platform(s) and make sure they are checked
    listingData?.listingPlatforms?.forEach((listingPlatform) => {
      const index = checkedState.findIndex((platform) => platform.id === listingPlatform.platform.id)
      if (index > -1) checkedState[index].checked = true
    })

    setCheckedState(checkedState)
    setHideDelist(!isEnterprise && isConsigned(product) && listingData?.listingPlatforms.length > 0)
  }, [listingData])

  useEffect(() => {
    if (!credential || isEmpty(settingsStoreLocations)) return

    if (credential.data) {
      const merchants = getMerchant(credential)

      const storeLocationsMap = keyBy(settingsStoreLocations, 'id')

      const cloverMappedStoreLocations = chain(merchants)
        .filter((merchant) => has(storeLocationsMap, merchant.value?.storeLocationId))
        .map((merchant) => ({
          label: storeLocationsMap[merchant.value?.storeLocationId].name,
          value: `clover:${merchant.platform.id}:${merchant.refId}`,
        }))
        .value()

      setCloverStoreLocations(cloverMappedStoreLocations)
    }
  }, [settingsStoreLocations, credential?.data])

  const onBack = useOnBack()

  const delistSelected = async () => {
    setIsLoading(true)
    setToastType(ToastType.UNDEFINED)

    try {
      const token = await getAccessTokenSilently()

      await axios.delete(`${apiConfig.api_url}/listing/many`, {
        data: {ids: [listingData.id]},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setToastType(ToastType.SUCCESS)
      setToastContent('Selected listings have been taken down!')
      await new Promise((resolve) => setTimeout(resolve, 3000))
      setTimeout(() => {
        setIsLoading(false)
        routeChange('/admin/inventory/viewInventory')
      }, 100)
    } catch (err) {
      captureException(err)
      if (err.response && err.response.status == 401) setPremiumModal(true)
      setIsLoading(false)
      setToastType(ToastType.ERROR)
      setToastContent('Delisting failed, please try again.')
    }
  }

  const onFormSubmit = async () => {
    const requiredProperties = ['desiredReturn']
    const formError = _validateFormFields(formFields, requiredProperties)
    setErrorMessage(formError)

    if (!formError) {
      setIsLoading(true)
      try {
        const token = await getAccessTokenSilently()
        const response = await axios.patch(
          `${apiConfig.api_url}/listing/${listingData.id}`,
          {
            quantity: formFields?.quantity,
            price: formFields?.desiredReturn,
          },
          createRequestOptions(token),
        )

        const updatedForm = getPredefinedObject(product, [
          'acquiredDate',
          'assets',
          'boxCondition',
          'boxDimensions',
          'boxWeight',
          'brand',
          'category',
          'color',
          'comments',
          'condition',
          'description',
          'flaws',
          'id',
          'internalSku',
          'location',
          'quantity',
          'releaseDate',
          'retailPrice',
          'shippingMethod',
          'size',
          'sku',
          'subLocation',
          'subcategory',
          'tagStatus',
          'title',
          'feeStructure',
        ])

        await axios.patch(
          `${apiConfig.api_url}/product/${product.id}`,
          {
            ...updatedForm,
            price: formFields?.price || 0,
            desiredReturn: formFields?.desiredReturn,
          },
          createRequestOptions(token),
        )
        setIsLoading(false)
        if (response.status === 200) {
          refetchInventory()
          // TODO: What's the use of this since no API has been called
          let savedPlatforms = (response && response.data && response.data.platforms) || []
          let failedAdditions = []
          let failedDeletions = []
          if (!savedPlatforms) {
            savedPlatforms = []
          }
          if (!formFields.platforms) {
            formFields.platforms = []
          }
          if (response.data === null) {
            setDeletionModal(true)
          }
          failedAdditions = formFields.platforms.filter(
            (sel) => !savedPlatforms.some((r) => r.name.toLowerCase() === sel.value.toLowerCase()),
          )
          failedDeletions = savedPlatforms.filter(
            (r) => !formFields.platforms.some((sel) => sel.value.toLowerCase() === r.name.toLowerCase()),
          )
          if (
            (failedAdditions && failedAdditions.length > 0) ||
            (failedDeletions && failedDeletions.length > 0)
          ) {
            setDialogRoute(
              response && response.data && response.data.item
                ? `/admin/listings/viewListing/${response.data.item.id}`
                : `/admin/inventory/viewItem/${formFields.listItem.id}`,
            )
            setFailedAdditionsModal(failedAdditions)
            setFailedDeletionsModal(failedDeletions)
          } else if (response && response.data && response.data.item) {
            history.push({pathname: `/admin/listings/viewListing/${response.data.item.id}`})
          } else {
            history.push({pathname: `/admin/listings/viewListings`})
          }
        }
      } catch (err) {
        refetchInventory()
        captureException(err)
        setIsLoading(false)
        setToastType(ToastType.ERROR)
        setToastContent('Error updating listing')
      }
    }
  }

  const routeChange = (route, data) => {
    history.push({pathname: route, data: data})
  }

  const handleCheckbox = (e, position) => {
    checkedState.forEach((item, index) => {
      if (index === position) {
        item.checked = !item.checked
      }
    })
    setCheckedState(checkedState)

    setFormFields({
      ...formFields,
      platformIds: checkedState
        .filter((platformState) => platformState.checked)
        .map((platform) => platform.id),
    })
  }

  return (
    <Box className="p-detail-flex inventory edit-listing listing">
      {/* TODO: I'm not sure what the use of this. Update this and use CopytModal */}
      {((failedAdditionsModal && failedAdditionsModal.length > 0) ||
        (failedDeletionsModal && failedDeletionsModal.length > 0)) && (
        <Modal isOpen={true} centered={true} backdrop={true} className="listing-failure-modal">
          <ModalHeader tag="div" className="listing-failure-header">
            <AlertSvg />
            <div className="listing-failure-details">
              <div className="listing-failure-title">Partial failure</div>
              <div className="listing-failure-subtitle">
                {failedAdditionsModal && failedAdditionsModal.length > 0 && (
                  <span>
                    Could not post to{' '}
                    {failedAdditionsModal
                      .map((fp, i) =>
                        i > 0 && i === failedAdditionsModal.length - 1 ? 'and ' + fp.label : fp.label,
                      )
                      .join(', ')}
                    .&nbsp;
                  </span>
                )}
                {failedDeletionsModal && failedDeletionsModal.length > 0 && (
                  <span>
                    Could not delist from{' '}
                    {failedDeletionsModal
                      .map((fp, i) =>
                        i > 0 && i === failedDeletionsModal.length - 1 ? 'and ' + fp.name : fp.name,
                      )
                      .join(', ')}
                    .
                  </span>
                )}
                {deletionModal && <span>No listings exist now for this item.</span>}
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="listing-failure-icon">
              <ListingFailureSvg />
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn-primary ripple btn-small cancel"
              onClick={() => history.push({pathname: dialogRoute})}
            >
              OK
            </button>
          </ModalFooter>
        </Modal>
      )}
      <CopytToolbar style={{justifyContent: 'flex-end'}}>
        {!hideDelist && !listingLoading && (
          <CustomButton
            disabled={
              isLoading ||
              (some(
                listingData.listingPlatforms.filter((lp) => lp.platform.type !== 'payment'),
                (s) =>
                  get(s, 'status') === ListingPlatformStatus.PENDING ||
                  get(s, 'status') === ListingPlatformStatus.PROCESSING,
              ) &&
                !!DateUtils.calculateDateDifference(listingData?.listingPlatforms)?.isToOld)
            }
            icon={<FilterListOffIcon />}
            title={isLoading ? 'Loading' : 'Delist'}
            onClick={delistSelected}
            color="error"
            style={{
              marginRight: 10,
              width: '150px',
            }}
          />
        )}
        <CustomButton
          icon={<CancelIcon />}
          disabled={isLoading}
          title="Cancel"
          onClick={onBack}
          variant="outline"
          style={{
            marginRight: 10,
            width: '150px',
          }}
        />
        <CustomButton
          icon={<SaveIcon />}
          disabled={isLoading}
          title={isLoading ? 'Loading' : 'Save'}
          onClick={onFormSubmit}
          style={{
            width: '150px',
          }}
        />
      </CopytToolbar>
      <DetailView.PanelDetail>
        <Grid container gap={1}>
          <Grid item xs={12} lg={6.5}>
            <Grid container gap={2}>
              <Grid item xs={12} md={5.5} lg={5.8}>
                <Overview formFields={product} isListing readOnly />
              </Grid>
              <Grid item xs={12} md={6} lg={5.8}>
                <ListingInventoryDetails {...{product}} readOnly />
              </Grid>
            </Grid>
            <InventoryImages formFields={product} isListing />
          </Grid>
          <Grid item xs={12} lg={5}>
            <InventoryPricing
              {...{errorMessage, isEnterprise, product}}
              isConsigned={product?.consign?.status?.toLowerCase() === 'listed'}
              formFields={Object.assign(product, {
                desiredReturn,
                price: formFields?.price,
              })}
              onChange={(e) => {
                Object.entries(e).map(([key, value]) => {
                  if (key === 'desiredReturn') {
                    setDesiredReturn(parseFloat(value))
                  }
                  const val = isNaN(parseFloat(value)) ? '' : parseFloat(value)
                  setFormFields({...formFields, [key]: val})
                })
              }}
              readOnly
              hasError={errorMessage?.length > 0}
            />
            <InventoryPlatforms
              {...{isEnterprise, handleCheckbox}}
              cloverSelectedStoreLocation={
                find(cloverStoreLocations, (cl) => cl?.label === product?.location)?.value
              }
              cloverStoreLocations={cloverStoreLocations}
              platformOptions={platformOptions}
              platformSelection={platformSelection}
              isDisabled
            />
            <ConsignorInformation
              setFormFields={setFormFields}
              platforms={listingData?.listingPlatforms}
              consignor={product?.consign}
              consignorEmail={product?.consign?.consignor?.email || ''}
              feeStructure={formFields?.feeStructure || product?.feeStructure}
              formFields={{
                ...formFields,
                desiredReturn,
              }}
              isConsignorEmailReadOnly
              isFeeStructureReadOnly
            />
            {isEnterprise && (
              <InventoryTracking
                {...{
                  isEnterprise,
                  setFormFields,
                }}
                isReadOnly
                formFields={product}
                location={product?.location}
                internalSku={product?.internalSku}
              />
            )}
          </Grid>
          {toastType > ToastType.UNDEFINED && (
            <InventoryToast {...{toastType, setToastType}} content={toastContent} />
          )}
        </Grid>
      </DetailView.PanelDetail>
      <Paywall modalToggle={setPremiumModal} modalState={premiumModal} history={history}></Paywall>
    </Box>
  )
}

export default EditListing

import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import {useQuery} from 'react-query'
import axios from 'axios'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'
import Qs from 'querystring'

const getDashboard = async (getAccessTokenSilently, filter) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths.sale}/stat-v2?${Qs.stringify({...filter})}`,
      createRequestOptions(token),
    )
    return response?.data?.data
  } catch (error) {
    Sentry.captureException(error)
    console.log('Error retrieving invoices', error)
    throw error
  }
}

export const useDashboard = (filter) => {
  const {getAccessTokenSilently, user} = useAuth0()

  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    data: dashboard = [],
    error,
  } = useQuery(
    ['dashboard', filter],
    () => getDashboard(getAccessTokenSilently, filter),
    {
      keepPreviousData: true,
    },
  )

  return {
    isLoading,
    isError,
    isFetched,
    refetch,
    error,
    dashboard: dashboard || [],
  }
}

export default useDashboard

import React, {useState, useEffect} from 'react'
import {NavLink, useHistory} from 'react-router-dom'
import {withAuth0} from '@auth0/auth0-react'
import {styled} from '@mui/material/styles'
import {Accordion, AccordionDetails, AccordionSummary, List} from '@mui/material'
import {ExpandMore} from '@mui/icons-material'

import {useGlobalStore} from 'provider/global_store/hook'
import TableView from '../tools/TableView'
import ListItemContent from './ListItemContent'

const MuiAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({theme}) => ({
    padding: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
    '&: MuiAccordionSummary-root': {
      padding: 0,
    },
  }),
)

const MuiAccordionDetails = styled(AccordionDetails)(({theme}) => ({
  padding: 0,
}))

const AdminSidebar = (props) => {
  const {isMobileScreen, rtlActive = false, shouldHide = false, routes, location} = props
  const {isEnterprise, pending} = useGlobalStore()
  const history = useHistory()
  const isExist = (routeName) => location.pathname.includes(routeName)
  const [expanded, setExpanded] = useState(false)

  const handleChange = (event, type, path) => {
    if (type === 'parent') {
      setExpanded(true)
    } else {
      setExpanded(!expanded)
    }
    history.push(path)
  }

  const createLinks = (routes) =>
    routes.map((r, key) => {
      if (r.redirect) return null
      const path = `${r.layout}${r.path}`
      const pendingCount = pending > 99 ? '99+' : pending

      const parentActive = r?.hasOwnProperty('hasChildren') && isExist(r?.name?.toLowerCase())

      if (r?.views && r?.hasChildren) {
        return (
          <MuiAccordion
            key={key}
            expanded={parentActive}
            onChange={handleChange}
            className={parentActive ? 'parent-active' : ''}
          >
            <AccordionSummary
              sx={{
                '& .MuiAccordionSummary-root': {padding: 0},
                '& .MuiAccordionSummary-content': {margin: 0},
                '& .MuiAccordionSummary-content > a': {width: 1},
              }}
            >
              <NavLink
                to={path}
                key={key}
                onClick={(event) => {
                  event.preventDefault()
                  handleChange(event, 'parent', path)
                }}
              >
                <ListItemContent
                  route={r}
                  isMobileScreen={isMobileScreen}
                  rtlActive={rtlActive}
                  pendingCount={pendingCount}
                  pending={pending}
                  shouldHide={shouldHide}
                />
              </NavLink>
            </AccordionSummary>
            <MuiAccordionDetails sx={{'& .MuiAccordionDetails-root': {padding: 0}}}>
              {r?.views.map((subRoute, subKey) => (
                <NavLink to={`${subRoute?.layout}${subRoute?.path}`} key={subKey}>
                  <ListItemContent
                    route={subRoute}
                    isMobileScreen={isMobileScreen}
                    rtlActive={rtlActive}
                    pendingCount={pendingCount}
                    pending={pending}
                    shouldHide={shouldHide}
                    isActive={isExist(`${subRoute.layout}${subRoute.path}`)}
                  />
                </NavLink>
              ))}
            </MuiAccordionDetails>
          </MuiAccordion>
        )
      }

      return (
        <NavLink to={path} key={key}>
          <ListItemContent
            route={r}
            isMobileScreen={isMobileScreen}
            rtlActive={rtlActive}
            pendingCount={pendingCount}
            pending={pending}
            shouldHide={shouldHide}
            isActive={isExist(path)}
          />
        </NavLink>
      )
    })

  return (
    <TableView>
      <TableView.Body>
        <List sx={{paddingTop: 0, width: isMobileScreen ? 65 : '100%'}}>
          {createLinks(
            routes
              .filter((x) => !x.hidden)
              .filter((x) => !x.enterpriseOnly || (isEnterprise && x.enterpriseOnly))
              .filter((x) => !x.individualOnly || (!isEnterprise && x.individualOnly)),
          )}
        </List>
      </TableView.Body>
    </TableView>
  )
}

export default withAuth0(AdminSidebar)

import colors_options from 'assets/data/options/colors_options'

const createChart = (obj, isLine = false) => {
  const {labels, data} = obj || {}
  if (data?.length === 0) {
    labels.push('No Data Available')
    data?.push(100)
  }
  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: isLine ? 'rgba(255, 99, 132, 0.5)' : [].concat(data).map((x, i) => colors_options[i]),
        borderWidth: isLine ? 3 : 1,
        borderColor: isLine ? '#5d0953' : 'white',
      },
    ],
  }

}

export default createChart

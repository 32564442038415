import React, {memo, useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Box, Typography, Link, Stack} from '@mui/material'
import {
  InfoOutlined as InfoOutlinedIcon,
  Storefront as StorefrontIcon,
  ShoppingBag as ShoppingBagIcon,
} from '@mui/icons-material'
import {styled} from '@mui/material/styles'
import {isEmpty, chain, cloneDeep, forEach, flatMap, filter} from 'lodash'
import SearchBar from 'material-ui-search-bar'

import PanelView from 'components/DetailView/PanelView'
import CopytCard from 'components/CopytCard'
import {ModalType} from 'enums'
import PlatformNames from 'enums/PlatformNames'
import {ReactComponent as UpgradeBanner} from 'assets/img/upgrade.svg'
import {ReactComponent as EcgBanner} from 'assets/img/ecg.svg'
import {LinkTooltip} from 'views_v2/lib/components'
import ProfileModals from 'views_v2/modules/Profile/ProfileModals'
import {CopytTooltip, CopytSwitch, EmptyData, TooltipSnippets, LoadingBar} from 'views_v2/lib/snippets'
import {useGlobalStore} from 'provider/global_store/hook'
import {useCredential} from 'service/hook'
import {usePlatform, validateReferralCode} from 'service/hook/use_platform'
import {getLogo, isCredentialEnabled} from 'util/model/platform'
import {PlatformType} from '../../../../constant/platform_type'

const platformArr = Object.values(PlatformNames)

const CardInfoText = styled(Typography)(() => ({
  fontSize: '0.8em',
  whiteSpace: 'normal',
  color: 'var(--gray-dark) !important',
}))

const PlatformBody = ({item, isEnterprise, routeChange, handleSwitch, setModalType}) => {
  let platform_logo = <StorefrontIcon sx={{marginRight: '16px'}} className="platform-icon" />

  const isThirdParty = platformArr.some((p) => p === item?.name?.toLowerCase())
  const membershipTypeECG = item?.memberships?.find((m) => m.type?.toLowerCase() === 'ecg')

  if (!isThirdParty && membershipTypeECG) {
    platform_logo = <EcgBanner className="ecg" />
  }
  if (isThirdParty) {
    platform_logo = (
      <>
        <Box
          className="platform-icon"
          component="img"
          sx={{
            width: '40px',
            height: '40px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}
          src={getLogo(item) || item.logo_url}
        />

        {!isEnterprise &&
          item?.name !== 'Ebay' &&
          item?.name !== 'Copyt Network' &&
          item?.name !== 'Paypal' &&
          !isCredentialEnabled(item) && <UpgradeBanner className="upgrade" />}
      </>
    )
  }

  return (
    <Stack direction="row" minWidth={{sm: '100%', md: '32%'}} key={item.id} mb={1}>
      <CopytCard
        id={item.name}
        onClick={
          item.type === PlatformType.MARKET || item.type === PlatformType.PAYMENT
            ? () =>
                item.consignmentId ||
                (!isEnterprise &&
                  item?.name !== 'Ebay' &&
                  item?.name !== 'Copyt Network' &&
                  item?.name !== 'Paypal')
                  ? setModalType(ModalType.NOTICE)
                  : item?.name === 'Copyt Network' && !isCredentialEnabled(item)
                  ? routeChange('/copytnetwork')
                  : routeChange('/admin/platforms/' + item.id)
            : undefined
        }
        className={item.consignmentId ? 'shop-platfrom-card' : 'platform-card'}
        style={{marginBottom: 0}}
      >
        <CopytCard.Body style={{minHeight: '100px'}}>
          <Stack
            direction="row"
            alignItems="center"
            minHeight="80px"
            justifyContent="space-between"
            width="100%"
          >
            <Stack
              direction="row"
              spacing={3}
              width="80%"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Box minWidth="25px">{platform_logo}</Box>
              <Stack direction="column">
                <Typography fontWeight={700} fontSize="1em" color="var(--success) !important">
                  <Box component="span">
                    <TooltipSnippets title={item.name} />
                    {item.name === 'Paypal' ? ' / Venmo' : ''}
                  </Box>
                </Typography>
                <Box>
                  {item.support_email && item.support_email !== 'null' && (
                    <CardInfoText fontSize="0.9rem">
                      Support:&nbsp;
                      <Box component="span" fontWeight={600}>
                        <Link href={`mailto:${item?.support_email}`} style={{color: 'inherit'}}>
                          {item?.support_email || '--'}
                        </Link>
                      </Box>
                    </CardInfoText>
                  )}
                </Box>
                <Box>
                  {item.faq_link && item.faq_link !== 'null' && (
                    <CardInfoText fontSize="0.9rem">
                      FAQ:&nbsp;
                      <Box component="span" fontWeight={600}>
                        {item?.faq_link || '--'}
                      </Box>
                    </CardInfoText>
                  )}
                </Box>
                <Box>
                  {item.contract_pdf_link && (
                    <Link href={item.contract_pdf_link} target="_blank" rel="noopener noreferrer">
                      <CardInfoText fontSize="0.9rem" fontWeight={600}>
                        Consignment Contract
                      </CardInfoText>
                    </Link>
                  )}
                </Box>
              </Stack>
            </Stack>
            <Stack direction="column" alignItems="center" width="20%" spacing={2}>
              {item.type === PlatformType.MARKET || item.type === PlatformType.PAYMENT ? (
                <>
                  <ShoppingBagIcon
                    sx={{
                      m: 0,
                      color: item?.credentials?.length > 0 ? 'var(--primary)' : '#d5239e30',
                    }}
                  />
                  <CopytSwitch
                    checked={isCredentialEnabled(item) || item?.name === 'Copyt Network'}
                    disabled={!!item.consignmentId}
                    onChange={(state) => handleSwitch(item, state)}
                  />
                </>
              ) : isEmpty(item?.credentials) === 0 ? (
                'Disabled'
              ) : (
                'Enabled'
              )}
            </Stack>
          </Stack>
        </CopytCard.Body>
      </CopytCard>
    </Stack>
  )
}

const PlatformManagement = (p) => {
  const {visible, handleSwitch} = p
  const {isEnterprise} = useGlobalStore()
  const history = useHistory()
  const {onCreateCredential} = useCredential()
  const [enabledFilterChecked, setEnabledFilterChecked] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const {platforms} = usePlatform({type: isEnterprise ? PlatformType.BOTH : PlatformType.USER})
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [validateSuccess, setValidateSuccess] = useState(false)
  const [network, setNetwork] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const routeChange = (route) => {
    history.push(route)
  }

  const handleFilter = () => {
    setEnabledFilterChecked(!enabledFilterChecked)
  }

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const filteredPlatforms = chain(platforms)
    .uniqBy('id')
    .filter(
      (p) =>
        p.name !== 'Elsewhere' &&
        p.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        !(enabledFilterChecked && !isCredentialEnabled(p)),
    )
    .value()

  useEffect(() => {
    setIsLoading(true)
    const fetchNetwork = async () => {
      const foundNetwork = platforms?.find((platform) => platform?.name?.includes('Network'))
      if (
        foundNetwork &&
        (foundNetwork?.credentials?.some((cr) => !cr?.enabled) || isEmpty(foundNetwork?.credentials))
      ) {
        let updatedNetwork = cloneDeep(foundNetwork)
        forEach(updatedNetwork.credentials, (credential) => {
          credential.enabled = true
        })
        setNetwork(updatedNetwork)

        const validate = await validateReferralCode({
          referral_code: 'ACD2023',
          platformId: foundNetwork.id,
        })
        if (validate.success) {
          onCreateCredential({platformId: foundNetwork.id, value: {}})
          setValidateSuccess(true)
        }
      }
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }

    fetchNetwork()
  }, [platforms, onCreateCredential])

  const typeArr = ['market', 'enterprise', 'regular', 'payment', 'ecg', 'user']
  const memoizedPlatforms = useMemo(() => {
    const platforms = flatMap(typeArr, (item) =>
      filter(
        filteredPlatforms,
        (r) =>
          r?.type === item &&
          (!isEnterprise ? r?.owner?.type !== 'enterprise' && r?.owner?.type !== 'regular' : true),
      ),
    )

    let hasNetwork = platforms.some((s) => s?.name?.includes('Network'))

    if (network && !hasNetwork && validateSuccess) {
      platforms.push(network)
      hasNetwork = true
    }
    return {platforms, hasNetwork, isLoading}
  }, [filteredPlatforms, typeArr, network, validateSuccess, isLoading])

  const emptyPlatforms = isEmpty(memoizedPlatforms?.platforms) && !memoizedPlatforms?.isLoading

  return (
    <PanelView
      isHidden={!visible}
      style={{
        height: '100%',
      }}
    >
      <CopytCard className="full-height">
        <CopytCard.Header
          style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
        >
          <CopytCard.Text tag="div">Platform Management</CopytCard.Text>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* Todo: Temporarily hide this toolbar */}
            {/* <CopytTooltip title="Show enabled platforms" placement="bottom">
              <CopytSwitch
                _textStart={{color: 'var(--success) !important', fontSize: '0.9rem', fontWeight: 600}}
                labelStart="Enabled"
                onChange={(e) => handleFilter(e)}
                checked={enabledFilterChecked}
              />
            </CopytTooltip> */}
            <SearchBar
              style={{
                height: '35px',
                border: '1px solid var(--lighter)',
              }}
              placeholder="Search..."
              cancelOnEscape
              onCancelSearch={(e) => handleSearch('')}
              onRequestSearch={(e) => handleSearch(e)}
            />
            <CopytTooltip
              title="To manage an account’s credentials, select the platform you wish to update. To enable or
              disable a platform that has already been initiated, simply select the toggle in the top
              right corner of that platform’s card."
              placement="bottom"
            >
              <InfoOutlinedIcon fontSize="small" sx={{color: 'var(--success)'}} />
            </CopytTooltip>
          </Stack>
        </CopytCard.Header>
        <CopytCard.Body style={{height: '100%', maxHeight: '80vh', overflowY: 'auto'}}>
          {memoizedPlatforms?.isLoading && !memoizedPlatforms.hasNetwork ? (
            <Stack
              direction="column"
              spacing={1}
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <LoadingBar type="circular" />
              <Typography textAlign="center" color="var(--success) !important" fontSize="0.9rem">
                Please wait
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction="row"
              flexWrap="wrap"
              rowGap={1.5}
              columnGap={2}
              height={emptyPlatforms ? '100%' : 'auto'}
              justifyContent="flex-start"
              alignItems="center"
            >
              {emptyPlatforms ? (
                <EmptyData title="We could not find any items matching your criteria" />
              ) : (
                memoizedPlatforms?.platforms.map((item) => {
                  if (
                    !item.consignmentId &&
                    (item.type === PlatformType.MARKET || item.type === PlatformType.PAYMENT)
                  ) {
                    let title =
                      isEnterprise || item?.name === 'Ebay' || item?.name === 'Copyt Network'
                        ? 'Manage Platform'
                        : 'Upgrade to Enterprise'
                    if (item?.name === 'Copyt Network' && !isCredentialEnabled(item))
                      title = 'Upgrade to access Copyt Network'
                    return (
                      <CopytTooltip {...{title}} placement="top" key={`platform-${item?.id}`}>
                        <PlatformBody
                          {...{item, isEnterprise, routeChange, handleSwitch, setModalType}}
                        />
                      </CopytTooltip>
                    )
                  } else {
                    return (
                      <PlatformBody
                        {...{item, isEnterprise, routeChange, handleSwitch, setModalType}}
                        key={`platform-${item?.id}`}
                      />
                    )
                  }
                })
              )}
            </Stack>
          )}
        </CopytCard.Body>
      </CopytCard>
      <ProfileModals
        {...{
          isEnterprise,
          history,
          modalType,
          setModalType,
        }}
      />
    </PanelView>
  )
}

export default memo(PlatformManagement)

import React, {useMemo} from 'react'
import {Card, CardHeader, CardBody, CardTitle, Label, FormGroup, Input, Row, Col} from 'reactstrap'
import {getDefaultPlatformName} from 'util/model/listing'
import {getDefaultLP} from 'util/model/listing'
import {getSoldPlatformName, getSold} from 'util/model/listing'
import {soldDuration} from 'views/listings/util'

export const SoldListingDetails = ({listing}) => {
  // todo: shift to global obj
  //   const {data: listingMembers = ar} = usePlatformMember()

  const status = listing.status
  const totalSoldPrice = listing.totalSoldPrice
  const soldData = useMemo(() => getSold(listing), [listing])
  const processData = useMemo(() => getDefaultLP(listing), [listing])
  const soldPlatformName = useMemo(() => getSoldPlatformName(listing), [listing])
  const processPlatformName = useMemo(() => getDefaultPlatformName(listing), [listing])
  const postFee = useMemo(() => Number(soldData?.price) - Number(soldData?.shippingFee), [soldData])
  const duration = useMemo(
    () => soldDuration(listing?.createdAt, soldData?.createdAt),
    [listing, soldData],
  )

  const processDuration = useMemo(
    () => soldDuration(listing?.createdAt, processData?.createdAt),
    [listing, processData],
  )

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="div">
          Listing details
          <div className={status == 'sold' ? 'sold-status' : 'processed-status'}>
            {status == 'sold' ? 'Sold' : 'Processed'}
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <FormGroup>
              <Label className="read-only-label">Platform</Label>
              <Input readOnly value={soldPlatformName || processPlatformName || ''}></Input>
            </FormGroup>
            <FormGroup>
              <Label className="read-only-label">Sell Price</Label>
              <Input readOnly value={Number(soldData?.price).toFixed(2) || Number(processData?.price).toFixed(2) || ''}></Input>
            </FormGroup>
            <FormGroup>
              <Label className="read-only-label">Return</Label>
              <Input readOnly value={soldData?.actualReturn || ''}></Input>
            </FormGroup>
          </Col>
          <Col style={{paddingLeft: '14px', paddingRight: '0px'}}>
            <FormGroup>
              <Label className="read-only-label">Listing duration</Label>
              <Input readOnly value={soldData ? duration : processDuration}></Input>
            </FormGroup>
            <FormGroup>
              <Label className="read-only-label">Shipping Cost</Label>
              <Input readOnly value={Number(soldData?.shippingFee).toFixed(2) || ''}></Input>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

import {useState, useEffect} from 'react'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {captureException} from '@sentry/react'

import {paths} from 'constant/api'
import {useToastMessage} from 'components/index'
import {createRequestOptions} from 'util/network'
import {sortBy} from 'util/array_utils'
import {fn} from 'variables/empty'

const getFees = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(paths.feeType, createRequestOptions(token))

    return sortBy('name', response.data.data)
  } catch (error) {
    captureException(error)
    console.log('Error retrieving invoices', error)
    throw error
  }
}

export const useFees = ({onSuccessUpdate} = {onSuccessUpdate: fn}) => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const [fetchEnabled, setFetchEnabled] = useState(true)

  const {
    status,
    isLoading,
    isError,
    isFetched,
    refetch,
    data: fees = [],
    error,
  } = useQuery('application', () => getFees(getAccessTokenSilently), {
    enabled: fetchEnabled,
    keepPreviousData: true,
  })

  const add = useMutation(
    async ({name, value, customPayout}) => {
      const token = await getAccessTokenSilently()

      return await axios.post(
        paths.feeType,
        {
          name,
          value: parseInt(value, 10),
          customPayout,
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Fee for the consignor has been added.`,
        })
        setFetchEnabled(true)
        refetch()
      },
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchEnabled(true)
        refetch()
      },
    },
  )

  const setDefault = useMutation(
    async ({id, customPayout}) => {
      const token = await getAccessTokenSilently()

      return await axios.post(
        `${paths.feeType}/set-default`,
        {
          id: parseInt(id, 10),
          customPayout,
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Fee for the consignor is default.`,
        })
        setFetchEnabled(true)
        refetch()
      },
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchEnabled(true)
        refetch()
      },
    },
  )

  const updateFee = useMutation(
    async ({name, id, fee, customPayout}) => {
      const token = await getAccessTokenSilently()

      return await axios.patch(
        `${paths.feeType}/${id}`,
        {
          id,
          name,
          value: parseInt(fee, 10),
          customPayout,
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Fee for the consignor is updated.`,
        })
        setFetchEnabled(true)
        refetch()
        onSuccessUpdate()
      },
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchEnabled(true)
        refetch()
      },
    },
  )

  const assignFeeToApplication = useMutation(
    async ({applicationId, feeId}) => {
      const token = await getAccessTokenSilently()

      return await axios.post(
        `${paths.application}/fee-type/assign`,
        {
          applicationId: String(applicationId),
          feeTypeId: String(feeId),
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Fee is assigned to a consignor successfully.`,
        })
        setFetchEnabled(true)
        refetch()
      },
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchEnabled(true)
        refetch()
      },
    },
  )

  const deleteFee = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()

      return await axios.delete(`${paths.feeType}/${id}`, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Fee has been successfully deleted.`,
        })
        setFetchEnabled(true)
        refetch()
      },
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchEnabled(true)
        refetch()
      },
    },
  )

  useEffect(() => {
    if (fetchEnabled) {
      refetch()
      setFetchEnabled(false)
    }
  }, [fetchEnabled, refetch])

  return {
    status,
    isLoading,
    refetchFees: () => setFetchEnabled(true),
    isError,
    isFeesFetched: isFetched,
    error,
    fees: fees || [],
    addFee: add.mutate,
    setDefault: setDefault.mutate,
    updateFee: updateFee.mutate,
    deleteFee: deleteFee.mutate,
    assignFeeToApplication: assignFeeToApplication.mutate,
    isUpdateFeeToApplicationSuccess: assignFeeToApplication.isSuccess,
  }
}

export default useFees

import React from 'react'
import { Box, Grid, Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Slider } from '@mui/material'
import { styled } from '@mui/material/styles';
import { CopytTooltip } from 'views_v2/lib/snippets'
import { withStyles } from '@material-ui/core/styles'
import {
  InfoOutlined as InfoOutlinedIcon
} from '@mui/icons-material'
import { _getPercentage } from 'util/string_utils'

export const Item = styled(Paper)(() => ({
  color: '#140010',
  marginBottom: '25px'
}));

const SellSlider = withStyles({
  root: {
    color: '#5D0953',
    padding: 0
  },
  disabled: {
    color: '#5D0953 !important',
  },
  thumb: {
    height: 0,
    width: 0,
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);


export const CardItem = ({
  title,
  tooltipTitle,
  x,
  y
}) => {
  const percentage = _getPercentage(x, y) || 0
  return (
    <CopytTooltip title={tooltipTitle}>
      <Item key={2} elevation={2}>
        <Grid container spacing={2} className="inventory-sell">
          <Grid item xs={2}>
            <Box className="inventory-sell--percentage">
              <Typography>{percentage}%</Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box sx={{padding: '0 16px'}}>
              <List sx={{pt: 0}}>
                <ListItem alignItems="flex-start" style={{padding: '0 16px 0 0'}}>
                  <ListItemText
                    primary={
                      <Box className="inventory-sell--title-box">
                        <Typography>{title}</Typography>
                        <ListItemIcon sx={{ minWidth: '12px', fontSize: 1, margin: 1 }}><InfoOutlinedIcon /></ListItemIcon>
                      </Box>
                    }
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      letterSpacing: 0,
                    }}
                    secondary={
                      <Box className="inventory-sell--slider-box">
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body1"
                          fontWeight={600}
                          color="black"
                        >
                          {x}
                        </Typography>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                        >
                          /{y}
                        </Typography>
                        <SellSlider defaultValue={Number(percentage)} disabled />
                        <Typography className="inventory-sell--slider-box---lbl" gutterBottom>
                          {`Sold through ${percentage}% of beginning inventory`}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Item>
    </CopytTooltip>

  )

}

export default CardItem

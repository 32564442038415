import React, {useEffect, useState} from 'react'
import {Box, Checkbox, FormControlLabel, Typography, Stack} from '@mui/material'
import styled from 'styled-components'
import {ModalContainer, ModalHeading, ModalBody, ModalFooter} from 'views_v2/lib/snippets/CopytModal'
import {CopytButton} from 'views_v2/lib/snippets'

export const cardList = [
  {id: 'title', title: 'Item Name'},
  {id: 'size', title: 'Size'},
  {id: 'condition', title: 'Condition'},
  {id: 'location', title: 'Location'},
]

export const CopytBtn = styled(CopytButton)({
  width: '30%',
  height: 35,
  borderRadius: 6,
})

const MdlCardOptions = (p) => {
  const [selectedCriteria, setSelectedCriteria] = useState(p?.selectedCriteria)
  const [showCard, setShowCard] = useState(false)

  const handleCheckboxChange = (event, criteriaId) => {
    if (event.target.checked) {
      setSelectedCriteria([...selectedCriteria, criteriaId])
    } else {
      setSelectedCriteria(selectedCriteria.filter((id) => id !== criteriaId))
    }
  }

  const handleProceedClick = () => {
    p?.handleCardList(selectedCriteria)
    p?.onCloseModal()
  }

  const handleResetClick = () => {
    p?.handleCardList([
      'title',
      'size',
      'condition',
      'price',
      'desiredReturn',
      'assets',
      'sku',
      'location',
    ])
    p?.onCloseModal()
  }

  useEffect(() => {
    const retrievedObject = localStorage.getItem('inventory_card_view')
    const cardObject = JSON.parse(retrievedObject)
    setShowCard(!cardObject.hidden)
  }, [])

  return (
    <ModalContainer>
      <Box className="modal-title">
        <ModalHeading className="modal__heading">
          <Typography variant="h1">Choose Option</Typography>
        </ModalHeading>
      </Box>
      <ModalBody>
        <Stack direction="column" alignItems="center">
          {cardList.map((c) => {
            const checked = selectedCriteria.some((s) => s === c.id)
            return (
              <FormControlLabel
                key={c.id}
                value={c.id}
                onChange={(e) => handleCheckboxChange(e, c.id)}
                control={<Checkbox key={c.id} checked={checked} sx={{mr: '10px !important'}} />}
                label={c.title}
                sx={{
                  width: '160px',
                  marginBottom: '0 !important',
                }}
              />
            )
          })}
        </Stack>

        <ModalFooter mt={2}>
          {!showCard ? (
            <CopytBtn variant="contained" color="primary" onClick={handleProceedClick}>
              Confirm
            </CopytBtn>
          ) : (
            <>
              <CopytBtn variant="outline" color="primary" onClick={handleResetClick}>
                Reset
              </CopytBtn>
              <CopytBtn variant="contained" color="primary" onClick={handleProceedClick}>
                Update
              </CopytBtn>
            </>
          )}
        </ModalFooter>
      </ModalBody>
    </ModalContainer>
  )
}

export default MdlCardOptions

import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {useToastMessage} from 'components'
import * as Sentry from '@sentry/react'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'
import {ar} from 'variables/empty'
import Qs from 'querystring'
import {map} from 'lodash'

const getOrders = async (getAccessTokenSilently, filter = {}, queries = {}, searchText = '') => {
  try {
    const token = await getAccessTokenSilently()
    const queryString = map({ ...filter, ...queries, search: searchText }, (value, key) =>
      `${key}=${encodeURIComponent(value).replace(/%2F/g, '/')}`
    ).join('&')

    const response = await axios.get(
      `${paths.sold}?${queryString}`,
      createRequestOptions(token),
    )

    return response?.data
  } catch (error) {
    Sentry.captureException(error)
    console.error('Error retrieving invoices', error)
    throw error
  }
}

export const useOrders = (filter = {}, queries = {}, searchText = '') => {
  const {getAccessTokenSilently, user} = useAuth0()
  const {showToast} = useToastMessage()

  const {isLoading, isError, isFetching, isFetched, refetch, data, error} = useQuery(
    [`orders`, filter, searchText],
    () => getOrders(getAccessTokenSilently, filter, queries, searchText),
    {
      keepPreviousData: true,
    },
  )

  const updateBatchPayments = useMutation(
    async (data) => {
      const {ids} = data
      const token = await getAccessTokenSilently()

      return await axios.post(
        `${paths.order}/batch`,
        {
          ids: ids,
          amount: 1000,
          source: 'check',
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Success', body: 'Batches have been added'})
      },
    },
  )

  const checkoutAll = useMutation(
    async (data) => {
      const {ids} = data
      const token = await getAccessTokenSilently()

      console.log(ids)

      return await axios.post(`${paths.order}/batch/all`, {}, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Success', body: 'Batches have been check out'})
      },
    },
  )

  return {
    isLoading,
    isFetching,
    isError,
    isOrdersFetched: isFetched,
    refetchOrders: refetch,
    error,
    orders: data?.data || ar,
    data: data,
    updateBatchPayments: updateBatchPayments.mutate,
    checkoutAll: checkoutAll.mutate,
    isUpdateBatchPaymentsSuccess: updateBatchPayments.isSuccess,
    isCheckoutAllSuccess: checkoutAll.isSuccess,
  }
}

export default useOrders

export const createAddress = (ownerId, address) => {
  return {
    name: address?.name,
    street1: address?.street1,
    street2: address?.street2,
    city: address?.city,
    state: address?.state,
    zip: address?.zip,
    country: 'US',
    phone: address?.phone,
    email: address?.email,
  }
}

export default createAddress

export default {
  UNDEFINED: -1,
  OTHER: 1000,
  REMOVE: 0,
  BATCH: 1,
  LIST_ALL_PLATFORM: 2,
  CREATE_BATCH: 3,
  PAYWALL: 4,
  DELETE_ALL: 5,
  NOTICE: 6,
  UPGRADE_ACCOUNT: 7,
  PROCESS_ALL: 8,
  DUPLICATE_ITEM: 9,
  AUDIT: 10,
  SEND_CONNECTION_REQUEST: 11,
  EDIT_DISCORD_NAME: 12,
  CONNECT_BATCH_ITEMS: 13,
  ADD_STORE_LOCATIONS: 14,
  SKU_UPC: 15,
  SUPPORT: 16,
  LISTING_FAILURES: 17,
  CONTINUE: 18,
  BARCODE_TEMPLATE: 19,
  UPDATE_STORE_LOCATION: 20,
  CANCEL_ITEM: 21,
  UPLOAD_IMAGE_FAILED: 22,
  ADDITIONAL_FIELDS: 23,
  DATE_PICKER: 24,
  MARK_AS_SOLD: 25,
  BULK_EDIT: 26,
  BULK_EDIT_LAYOUT: 27,
  WITHDRAW: 28,
  CARD_OPTIONS: 29,
  CONSIGNMENT_CONTRACT: 30,
  SELECT_APPROVED_ITEMS: 31,
  CREATE_BOX: 32,
  ECG_ITEM_SUBMISSION: 33,
  CONSIGNOR_PROFILE: 34,
  CHOOSE_CONSIGNEE: 35,
  CREATE_LABEL: 36,
  PACKAGE_DETAILS: 37,
  ADDRESS: 38,
  CREATE_BOX_PRESET: 39,
  ORDER_FULFILLMENT: 40,
  PURCHASE: 41,
  PURCHASE_LABEL: 42,
  LABEL_PURCHASED: 43,
  PAYMENT_METHOD: 44,
  DISCOUNT_CODE: 45,
  SHIPMENT_DETAILS: 46,
}

import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import { pink } from '@mui/material/colors'
import { Person as PersonIcon } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core/styles'
import CopytCard from 'components/CopytCard'
import { formatName } from 'util/string_utils'

const ProfileInformation = (profile) => {
	const useStyles = makeStyles(() => ({
		halfCircle: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginTop: '40px',
			width: '100%',
			height: '91%',
			backgroundColor: "#fcecf8",
			borderTopRightRadius: '100%'
		},
		personIcon: {
			color: '#d5239e',
			width: '5rem',
			height: '5rem',
		},
	}));
	const classes = useStyles();
	return (
		<CopytCard className="copyt-card" style={{ height: '95%' }}>
			<CopytCard.Body className={classes.halfCircle}>
				<Avatar sx={{ bgcolor: pink[50], width: '8rem', height: '8rem', border: '5px solid' }}>
					{profile.logo ? (
						<Box
							component="img"
							sx={{
								width: '5rem',
								padding: '2px',
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center center',
							}}
							src={profile.logo}
						/>
					): <PersonIcon sx = {{ color: '#d5239e', width: '5rem', height: '5rem' }} />}
				</Avatar>
				<Box textAlign="center" mt={3}>
					<Typography color="#390533cc !important" fontSize="1.6rem" fontWeight={500}>{formatName(profile) || 'No name'}</Typography>
					<Typography color="#390533cc !important" fontSize="1.2rem" fontWeight={500}>
						{profile.city || 'No city'}
					</Typography>
				</Box>
			</CopytCard.Body>
		</CopytCard>
	)
}

export default ProfileInformation

import React from 'react'
import { Line } from 'react-chartjs-2'
import { Box, Button, Grid, Typography, Select, FormControl, MenuItem, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { CopytTooltip } from 'views_v2/lib/snippets'
import _ from 'lodash'
import { getCurrency } from 'util/model/setting'

const days = [
	'120 Days',
	'90 Days',
	'60 Days',
	'30 Days',
];

const PercentageText = styled(Typography)(() => ({
	fontSize: '1em',
	color: '#02B15A',
	border: '1px solid #E0E0E0',
	borderRadius: '10px',
	background: '#d9f3e6',
	width: '93px',
	height: '25px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-around'
}));

export const options = {
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false
		},
		tooltips: {
			callbacks: {
				label: function(tooltipItem) {
					return tooltipItem.yLabel;
				}
			}
		}
	}
}

export const Item = styled(Paper)(() => ({
	color: '#140010',
	marginBottom: '25px',
	height: '100%'
}))

export const LineChart = (p) => {
	const { data, title, expandIcon, onExpand } = p
	let total = 0
	if (Array.isArray(data?.datasets) && data?.datasets?.length > 0) {
		total = _.sum(data?.datasets[0]?.data)
	}

	const currency = getCurrency()

	return (
		<CopytTooltip title="Sum of the List Price of current Unlisted and Listed items." placement="right">
			<Item key={2} elevation={10}>
				<Grid container spacing={2} className="line-chart">
					<Grid item xs={12}>
						<Box className="line-chart--box">
							<Typography color="#140010" fontWeight={600} variant="body2" fontSize="1rem">{title}</Typography>
							<Button className="btn-primary" onClick={onExpand}>{expandIcon}</Button>
						</Box>
					</Grid>
					<Grid item xs={12} style={{ display: 'flex', justifyContent: "space-between", paddingRight: "16px"}}>
						<Box display="flex" gap="100px" alignItems="center" sx={{ pl: 2 }}>
							<Typography color="black" fontWeight={400} variant='h6'>{currency.format(total)} </Typography>
							<PercentageText>--%</PercentageText>
						</Box>
						<Box>
							<FormControl sx={{ m: 1, minWidth: 120 }} disabled className="line-chart--days">
								<Select
									value="120 Days"
								>
									{days.map((day) => (
										<MenuItem
											key={day}
											value={day}
										>
											{day}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</Grid>
					<Grid item xs={12} sx={{pr: 2}} style={{paddingLeft: '32px'}}>
						<Line
							{...{ data, options }}
							id="lineChart"
						/>
					</Grid>
				</Grid>
			</Item>
		</CopytTooltip>
	)
}

export default LineChart

import React from 'react'
import {chain} from 'lodash'
import Select from 'react-select'
import {Checkbox, FormControlLabel} from '@mui/material'
import {ReactComponent as PlatformsEmptyStateSvg} from 'assets/img/platforms-empty-state.svg'
import CopytCard from 'components/CopytCard'
import {CopytLabel, EmptyData} from 'views_v2/lib/snippets'
import {_checkForClover} from 'util/array_utils'

const InventoryPlatforms = (props) => {
  const {
    isEnterprise,
    platformSelection = [],
    platformOptions = [],
    cloverStoreLocations = [],
    handleCheckbox = () => null,
    onChangeCloverDropdown = () => null,
    cloverSelectedStoreLocation = '',
    isDisabled = false,
  } = props

  const sortedPlatformOptions = chain(platformOptions)
    .filter((option) => option.label !== 'Paypal' && option.hasCred)
    .filter((option) => option.label !== 'Checkbook' && option.hasCred)
    .sortBy('label')
    .reverse()
    .value()
  const isClover = _checkForClover(platformOptions, platformSelection)
  const isEmpty = platformOptions.length === 0
  const isCopytNetwork =
    !isEnterprise &&
    !sortedPlatformOptions.find((platform) => platform.label === 'Copyt Network' && platform.hasCred)

  return (
    <CopytCard>
      <CopytCard.Header>
        <CopytCard.Text tag="div">Platforms</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        {isEmpty || isCopytNetwork ? (
          <EmptyData
            title="You haven't enabled any platforms yet."
            icon={<PlatformsEmptyStateSvg className="empty-state-svg" />}
          >
            To list this item, head over to your <a href="/admin/platforms/management">platforms</a> page
            to link your accounts and enable the {isCopytNetwork && 'Marketplace'} platforms you would
            like to list to.
          </EmptyData>
        ) : (
          <>
            <CopytLabel>Select platforms for this listing</CopytLabel>
            {sortedPlatformOptions &&
              sortedPlatformOptions.map((platform, index) => (
                <FormControlLabel
                  disabled={isDisabled}
                  key={index}
                  id={platform.id}
                  sx={{
                    m: 1,
                    color: 'var(--success)',
                  }}
                  control={<Checkbox checked={platformSelection?.some((pf) => pf === platform.id)} />}
                  onChange={() => handleCheckbox(platform.id)}
                  label={platform.label}
                />
              ))}
            {isClover && (
              <Select
                isDisabled={isDisabled}
                className="react-select info"
                classNamePrefix="react-select"
                placeholder="Clover Store Locations"
                closeMenuOnSelect
                isClearable
                value={cloverStoreLocations?.find((_) => _?.value === cloverSelectedStoreLocation)}
                onChange={onChangeCloverDropdown}
                options={cloverStoreLocations}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: isClover && !cloverSelectedStoreLocation ? 'red !important' : 'none',
                  }),
                }}
              />
            )}
            {isClover && !cloverSelectedStoreLocation && (
              <CopytLabel props={{error: true}}>Required to list to Clover</CopytLabel>
            )}
          </>
        )}
      </CopytCard.Body>
    </CopytCard>
  )
}

export default InventoryPlatforms

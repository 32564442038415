import React from 'react'
import { Box, Paper, Typography, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from '@mui/material'
import {styled } from '@mui/material/styles';
import { CopytTooltip } from 'views_v2/lib/snippets'
import { getCurrency } from 'util/model/setting';

export const Item = styled(Paper)(() => ({
  background: '#390533',
  color: '#fff',
  borderRadius: 30
  
}));

export const SummaryItem = ({
  title,
  tooltipTitle,
  icon,
  isMoney = false,
  value
}) => {
  const spanColor = true ? 'green' : 'red'
  const currency = getCurrency()
  return (
    <CopytTooltip title={tooltipTitle} placement="bottom">
      <Item key={2} elevation={2}>
        <Box className="icon-list-item">
          <List sx={{ width: '100%', padding: 0 }}>
            <ListItem alignItems="flex-start" sx={{padding: '0 16px 0 10px'}}>
              <ListItemAvatar sx={{ width:'25px', minWidth: 35, mr: 2.2}}>
                <ListItemIcon sx={{minWidth: 0, color: "white", m: 0}}>{icon}</ListItemIcon>
              </ListItemAvatar>
              <ListItemText
                primary={title}
                primaryTypographyProps={{
                  fontWeight: 'bold',
                  letterSpacing: 0,
                  color: "white",
                  fontSize: '1.2rem'
                }}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="white"
                      fontSize="1rem"
                    >
                      {isMoney ? `${currency.format(value)}` : value}
                    </Typography>
                    <span style={{float: 'right', color: spanColor, fontWeight: 600, fontSize: '0.95rem'}}>{" -- "}</span>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>

        </Box>
      </Item>
    </CopytTooltip>

  )

}

export default SummaryItem

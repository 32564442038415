import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { _getValue } from 'util/string_utils'

export const Item = styled(Paper)(() => ({
  color: '#140010'
}));

const otherKPIarr = ['Ave. Gross Margin','Ave. Transaction Value']

export const OtherKPIs = ({
  title,
  averageGross,
  averageTransaction
}) => {
  return (
    <Item key={2} elevation={2}>
      <Grid container spacing={2} className="inventory-sell--other-kpi">
        <Grid item xs={12}>
          <Box className="inventory-sell--other-kpi_title">
            <Typography>{title}</Typography>
            {/* <Button>View All</Button> */}
          </Box>
        </Grid>
        {otherKPIarr.map((s, key) => {
          const value = key ? averageTransaction : averageGross
          const color = s?.includes('Gross') ? 'green' : 'black'
          return (
            <Grid {...{key}} item xs={12} className="inventory-sell--other-kpi_data">
              <Box>
                <Box>
                  <Typography>{s}</Typography>
                  <Typography>Previously --</Typography>
                </Box>
              </Box>
              <Box className="">
                <Typography {...{ color }}>{_getValue(value)}</Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Item>
  )
}

export default OtherKPIs

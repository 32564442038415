import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {paths} from 'constant/api'
// import {ar} from 'variables/empty'
import {createRequestOptions} from 'util/network'
// import {useToastMessage} from 'components'
import * as Sentry from '@sentry/react'
import {fn} from 'moment'
import {useToastMessage} from 'components'
import {delay} from '../../util/util'

const getListingItem = async (id, tokenGetter) => {
  try {
    const token = await tokenGetter()
    const response = await axios.get(`${paths.listing}/${id}`, createRequestOptions(token))
    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error retrieving listing item', e)
    throw e.message
  }
}

export const updateListingStatus = async (data = {}, token) => {
  await axios.post(`${paths.listing}/status`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const postListing = async (input = {}, token, onSuccess = fn, onFailed = fn) => {
  try {
    const d = await axios.post(`${paths.listing}`, input, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    onSuccess(d?.data)
    return d?.data
  } catch (e) {
    onFailed('failed to submit')
  }
}

export const postListingRetry = async (input = {}, token, onSuccess = fn, onFailed = fn) => {
  try {
    const d = await axios.post(`${paths.listing}/retry`, input, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    onSuccess(d?.data)
    return d?.data
  } catch (e) {
    onFailed('failed to submit')
  }
}

export const useListingItem = (id, options = {}) => {
  const {getAccessTokenSilently} = useAuth0()
  return useQuery('listing/' + id, () => getListingItem(id, getAccessTokenSilently), options)
}

export const useListing = (id, options) => {
  // const {getAccessTokenSilently} = useAuth0()

  const item = useListingItem(id, options)

  return {
    item,
    listingLoading: item.isLoading,
    listingData: item.data,
    error: item.error,
  }
}

export const usePostListAll = ({onSuccess = fn}) => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()

  const postListAll = useMutation(
    async (data) => {
      const token = await getAccessTokenSilently()
      await delay(2000)
      return await axios.post(`${paths.listing}/list-all`, data, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Request is successfully submitted.`,
        })
        onSuccess()
      },
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
      },
    },
  )

  return postListAll
}

import React from 'react'
import clx from 'classnames'
import PropTypes from 'prop-types'
import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import {MdlAddress, MdlResetPassword, MdlCreditCard, MdlNewFee} from './component/modal-contents'

export const AccountSettingsModalType = {
  UNDEFINED: -1,
  RESET: 0,
  CREDIT_CARD: 1,
  NEW_FEE: 2,
}

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== AccountSettingsModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal})
      }
      return null
    })
  }
  return null
}

const AccountSettingsModal = (p) => {
  const {
    modalType,
    setModalType,
    modals,
    onFormSubmit,
    onSubmitLocation,
    onUpdateLocation,
    setLocForUpdate,
    locForUpdate,
  } = p
  const m = modals[modalType]

  return (
    <CopytModal
      className={m?.className || null}
      closeButton={m?.closeButton}
      id={m?.id}
      show={modalType > AccountSettingsModalType.UNDEFINED}
      size={m?.size || 'md'}
      toggle={() => setModalType(AccountSettingsModalType.UNDEFINED)}
    >
      <ModalContents
        focus={modalType}
        common={p}
        onCloseModal={() => setModalType(AccountSettingsModalType.UNDEFINED)}
      >
        <MdlResetPassword modalType={AccountSettingsModalType.RESET} />
        <MdlCreditCard
          modalType={AccountSettingsModalType.CREDIT_CARD}
          onSaveChanges={() => onFormSubmit()}
        />
        <MdlNewFee modalType={AccountSettingsModalType.NEW_FEE} />
        <MdlAddress modalType={ModalType.ADD_STORE_LOCATIONS} onSaveChanges={onSubmitLocation} />
        <MdlAddress
          modalType={ModalType.UPDATE_STORE_LOCATION}
          onSaveChanges={onUpdateLocation}
          isUpdate={true}
          locForUpdate={locForUpdate}
          setLocForUpdate={setLocForUpdate}
        />
      </ModalContents>
    </CopytModal>
  )
}

AccountSettingsModal.propTypes = {
  modals: PropTypes.object,
}

AccountSettingsModal.defaultProps = {
  modals: {
    [AccountSettingsModalType.RESET]: {
      className: 'password-reset-modal',
      size: 'xxs',
      closeButton: {
        hidden: true,
      },
    },
    [AccountSettingsModalType.CREDIT_CARD]: {
      className: 'credit-card-modal',
      closeButton: {
        hidden: true,
      },
    },
    [AccountSettingsModalType.NEW_FEE]: {
      className: 'no-padding',
      size: 'xs',
    },
    [ModalType.ADD_STORE_LOCATIONS]: {
      className: clx('copyt_modal_container', 'create-batch-modal'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.UPDATE_STORE_LOCATION]: {
      className: clx('copyt_modal_container', 'create-batch-modal'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
  },
}

export default AccountSettingsModal

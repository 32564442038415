import {apiConfig} from '../config'

export const paths = {
  itemType: `//${apiConfig.api_prefix}/itemType`,
  listing: `//${apiConfig.api_prefix}/listings`,
  listingJob: `//${apiConfig.api_prefix}/listing-job`,

  // v2 replacement
  user: `${apiConfig.api_url}/user/profile`,
  userPrivate: `${apiConfig.api_url}/userPrivate`,
  userSetting: `${apiConfig.api_url}/credential`,
  link: `${apiConfig.api_url}/link`,
  supportedPlatform: `${apiConfig.api_url}/platform/market`,
  product: `${apiConfig.api_url}/product`,
  platform: `${apiConfig.api_url}/platform`,
  referralCode: `${apiConfig.api_url}/referral-code`,
  item: `${apiConfig.api_url}/product`,
  listing: `${apiConfig.api_url}/listing`,
  market: `${apiConfig.api_url}/v2/api/market`,
  payment: `${apiConfig.api_url}/payment`,
  credential: `${apiConfig.api_url}/credential`,
  asset: `${apiConfig.api_url}/asset`,
  sneaks: `${apiConfig.api_url}/sneak/sku`,
  sneak: `${apiConfig.api_url}/sneak`,
  sale: `${apiConfig.api_url}/sale`,
  feeType: `${apiConfig.api_url}/fee-type`,
  productTemplate: `${apiConfig.api_url}/product-template`,
  consign: `${apiConfig.api_url}/consign`,
  consignmentBatch: `${apiConfig.api_url}/consignmentBatch`,
  order: `${apiConfig.api_url}/order`,
  ledger: `${apiConfig.api_url}/ledger`,
  sold: `${apiConfig.api_url}/sold`,
  application: `${apiConfig.api_url}/application`,
  descriptionTemplate: `${apiConfig.api_url}/descriptionTemplate`,
  siteSettings: `${apiConfig.api_url}/site-settings`,
  connection: `${apiConfig.api_url}/connection`,
  notification: `${apiConfig.api_url}/notification`,
  location: `${apiConfig.api_url}/location`,
  storeLocations: `${apiConfig.api_url}/platform/store-locations`,
  payout: `${apiConfig.api_url}/payout`,
  shipment: `${apiConfig.api_url}/shipment`,
  shipmentParcelPreset: `${apiConfig.api_url}/Shipment-parcel-preset`,
  discount: `${apiConfig.api_url}/discount`,

  //test
  applicationApproved: `${apiConfig.api_url}/application?status=approved`,
  locationByUser: (userId) => `${apiConfig.api_url}/location/user/${userId}`,
  productStock: `${apiConfig.api_url}/product/stock`,
}

export const defaults = {
  query_limit: 500,
}

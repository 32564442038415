import React, {useMemo, useState, useEffect, useCallback, useContext} from 'react'
import {capitalize, debounce, get, isEmpty, map, uniq} from 'lodash'
import {useHistory} from 'react-router-dom'
import {DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF} from '@mui/x-data-grid'
import {Box, Button, Stack} from '@mui/material'
import {styled} from '@mui/material/styles'
import {
  PendingActions as PendingActionsIcon,
  ThumbUpAlt as ThumbUpAltIcon,
  Error as ErrorIcon,
  List as ListIcon,
  ShoppingCart as ShoppingCartIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'

import DetailView from 'components/DetailView'
import {ModalType} from 'enums'
import {capitalizeFirstLetter, _getStatus} from 'util/string_utils'
import {getDesiredReturn, getLedgerValue} from 'util/model/consignment'
import {getCurrency} from 'util/model/setting'
import {EmptyData, LoadingBar, SearchToolbar, TooltipSnippets} from 'views_v2/lib/snippets'
import {SearchContext} from 'views_v2/lib/classes'
import {useConsignments} from 'service/hook'
import {ConsignmentFilter, StoreMenuFilter} from './snippets'
import PlatformsModal, {PlatformsModalType} from './PlatformsModal'

const DropdownFilter = styled(Button)(({props}) => ({
  ...props,
  outline: 'none !important',
  fontWeight: 600,
  borderRadius: '6px !important',
  border: '1px solid !important',
  paddingLeft: '10px !important',
  paddingRight: '10px !important',
  boxShadow: '1px 1px white',
  color: 'white !important',
  '&:hover': {
    bgcolor: 'transparent !important',
  },
}))

const ConsignmentOnIndividual = (props) => {
  const {
    filter,
    setFilter,
    currentPage,
    setCurrentPage,
    consignments,
    isLoading,
    isFetching,
    visible,
    onSetTabPanel,
  } = props
  const history = useHistory()
  const search = useContext(SearchContext)
  const onSearch = useCallback(
    debounce((searchKey) => search.setSearchText(searchKey), 1000),
    [],
  )
  const currency = getCurrency()
  const [statusFilter, setStatusFilter] = useState(null)
  const [storeName, setStoreName] = useState(null)
  const [statusAnchorEl, setStatusAnchorEl] = useState(null)
  const [storeAnchorEl, setStoreAnchorEl] = useState(null)
  const [consignee, setConsignee] = useState({value: null, label: null, location: 'default'})
  const [selectedLocation, setSelectedLocation] = useState(null)

  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const {approvedPlatforms, isFetchingItemsApproved, itemsApproved, refetchItemsApproved, consignShops} =
    useConsignments({take: 200}, null, consignee?.value)

  const [payload, setPayload] = useState({items: itemsApproved || [], boxItems: []})

  useEffect(() => {
    if (consignee?.value) {
      refetchItemsApproved({take: 200}, null, consignee?.value)
    }
  }, [consignee])

  useEffect(() => {
    if (modalType === ModalType.UNDEFINED) {
      setSelectedLocation(null)
      setConsignee({value: null, label: null, location: 'default'})
    }
  }, [modalType])

  useEffect(() => {
    if (consignee.value && !isFetchingItemsApproved) {
      setPayload({
        ...payload,
        items: itemsApproved,
      })
    }
  }, [consignee.value, isFetchingItemsApproved, itemsApproved])

  const handleClick = (event, type) => {
    event.stopPropagation()
    event.preventDefault()
    if (type === 'status') setStatusAnchorEl(event.currentTarget)
    else setStoreAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setStatusAnchorEl(null)
    setStoreAnchorEl(null)
  }

  const handleStatus = (status) => {
    setStatusFilter(status)
    setFilter((f) => ({...f, status}))
    handleClose()
  }

  const handleStoreName = (store) => {
    setStoreName(store?.label)
    setFilter((f) => ({...f, email: store?.value}))
    handleClose()
  }

  const storeNames = useMemo(() => {
    const names = uniq(
      map(consignShops, (item) => ({
        label: get(item, 'businessName', ''),
        value: get(item, 'email', ''),
      })),
    )

    return [{label: 'All Stores', value: ''}, ...names]
  }, [consignShops])

  useEffect(() => {
    return () => {
      onSearch.cancel()
    }
  }, [onSearch])

  const totals = (status) => {
    let overAllTotal = 0

    if (!consignments?.metrics) return 0

    if (status === 'sold') {
      overAllTotal = consignments?.metrics?.pendingPayout || 0
    } else if ('paid') {
      overAllTotal = consignments?.metrics?.receivedPayout || 0
    }
    return currency.format(overAllTotal)
  }

  const columns = useMemo(() => [
    {
      field: 'name',
      headerName: 'Item',
      flex: 2.3,
      minWidth: 170,
      renderCell: (params) => {
        const {product} = params?.row || {}
        const title = capitalizeFirstLetter(product?.title) || ''
        return <TooltipSnippets {...{title}} />
      },
      valueGetter: (params) => {
        const {product} = params?.row || {}
        return product?.title || ''
      },
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 0.7,
      minWidth: 50,
      valueGetter: (params) => {
        const {product} = params?.row
        return product?.size || ''
      },
    },
    {
      field: 'store',
      headerName: 'Store',
      flex: 1.2,
      minWidth: 130,
      renderCell: (params) => {
        const {consignee} = params?.row || {}
        const title = capitalizeFirstLetter(consignee?.businessName) || ''
        return <TooltipSnippets {...{title}} />
      },
      valueGetter: (params) => {
        const {consignee} = params?.row || {}
        return consignee?.businessName || ''
      },
    },
    {
      field: 'desiredReturn',
      headerName: 'Price',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return currency.format(getDesiredReturn(params.row))
      },
    },
    {
      field: 'batchPayout',
      headerName: 'Payout Received',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return currency.format(params.row.payout)
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.2,
      minWidth: 130,
      renderCell: (params) => {
        const {status} = params?.row
        let endIcon = <ThumbUpAltIcon />
        let color = 'success'
        const stat = status?.toLowerCase()
        if (stat === 'rejected') {
          endIcon = <ErrorIcon />
          color = 'error'
        } else if (stat === 'sold' || stat === 'processed') {
          endIcon = <ShoppingCartIcon />
          color = 'secondary'
        } else if (stat === 'active' || stat === 'listed') {
          endIcon = <ListIcon />
          color = 'primary'
        } else if (stat === 'submitted' || stat === 'pending') {
          endIcon = <PendingActionsIcon />
          color = 'secondary'
        }

        const _status = _getStatus(stat)
        return (
          <Button
            {...{color, endIcon}}
            size="small"
            variant="outlined"
            style={{
              pointerEvents: 'none',
              cursor: 'default',
              width: '100%',
              border: '1px solid',
            }}
          >
            <TooltipSnippets title={capitalize(_status)} />
          </Button>
        )
      },
    },
    {
      field: 'payout',
      headerName: 'Payout Info',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const row = params?.row
        let title = getLedgerValue(row)
        switch (row?.status) {
          case 'withdraw_pending':
            const initiatedBy =
              row?.withdrawnBy === row?.consignee?.id ? row?.consignee?.email : row?.consignor?.email
            title = `Initiated by ${initiatedBy}`
            break
          case 'withdraw_approved':
            title = `Approved by ${row?.consignee?.email}`
            break
          case 'withdraw_denied':
            title = `Denied by ${row?.consignee?.email}`
            break
        }
        return <TooltipSnippets {...{title}} />
      },
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.2,
    },
  ])

  const rowCount = consignments?.total || 0

  // if (isLoading) return <LoadingBar />
  const hasData = Array.isArray(consignments?.data) && !isEmpty(consignments?.data)

  const Toolbar = () => {
    return (
      <SearchToolbar
        onSearch={onSearch}
        onSelectApprovedItems={() => setModalType(ModalType.CHOOSE_CONSIGNEE)}
        hasCreateBox
      />
    )
  }

  return (
    <DetailView.Panel isHidden={!visible} style={{height: '100%'}}>
      {hasData || search?.searchText || statusFilter ? (
        <Box height="100%" sx={{boxShadow: '10px 10px 10px lightgrey'}}>
          <Stack
            direction="row"
            bgcolor="var(--primary)"
            padding={1.5}
            sx={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={5} alignItems="center">
              <Box fontWeight={600} color="white">
                Recieved Payouts: {totals('paid')}
              </Box>
              <Box fontWeight={600} color="white">
                Pending Payouts: {totals('sold')}
              </Box>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box>
                <DropdownFilter
                  id="customized-button"
                  className="options-btn"
                  aria-controls={statusAnchorEl ? 'customized-menu' : undefined}
                  aria-haspopup
                  aria-expanded={statusAnchorEl ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={(e) => handleClick(e, 'status')}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {statusFilter ? capitalizeFirstLetter(statusFilter) : 'All Status'}
                </DropdownFilter>
                <ConsignmentFilter
                  anchorEl={statusAnchorEl}
                  onClose={handleClose}
                  handleFilterClick={handleStatus}
                  open={Boolean(statusAnchorEl)}
                />
              </Box>
              <Box>
                <DropdownFilter
                  id="customized-button"
                  className="options-btn"
                  aria-controls={storeAnchorEl ? 'customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={storeAnchorEl ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={(e) => handleClick(e, 'store')}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {storeName ? capitalizeFirstLetter(storeName) : 'All Stores'}
                </DropdownFilter>
                <StoreMenuFilter
                  anchorEl={storeAnchorEl}
                  onClose={handleClose}
                  handleFilterClick={handleStoreName}
                  open={Boolean(storeAnchorEl)}
                  filterItems={storeNames}
                />
              </Box>
            </Stack>
          </Stack>
          <Box height="100%" paddingBottom="50px" flex={1}>
            <DataGrid
              {...{columns, rowCount}}
              rows={consignments?.data}
              loading={isLoading || isFetching}
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableVirtualization
              filterModel={{
                items: [],
                quickFilterValues: [search?.searchText],
              }}
              onFilterModelChange={(s) => {
                onSearch(s?.quickFilterValues[0])
              }}
              getRowClassName={(p) => 'datagrid-row'}
              components={{Toolbar: Toolbar}}
              componentProps={{
                toolbar: {
                  onSearch,
                },
              }}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              page={currentPage}
              pageSize={filter.take}
              pagination
              paginationMode="server"
              rowsPerPageOptions={[filter.take]}
            />
          </Box>
        </Box>
      ) : (
        <EmptyData title="No Consigned Items">
          Create your first batch of inventory on the
          <span
            onClick={() => history.push('/admin/inventory')}
            className="pink-text ml-1 cursor-pointer"
          >
            Inventory page
          </span>
          .
        </EmptyData>
      )}
      <PlatformsModal
        {...{
          approvedPlatforms,
          modalType,
          setModalType,
          storeName,
          payload,
          setPayload,
          consignee,
          setConsignee,
          isFetchingItemsApproved,
          onSetTabPanel,
          setSelectedLocation,
          selectedLocation,
        }}
        onHandlePrint={props?.onHandlePrint}
      />
    </DetailView.Panel>
  )
}

export default ConsignmentOnIndividual

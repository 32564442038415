import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Box, Grid, Typography } from '@mui/material'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export const DoughnutChart = ({ isHalfDonut = false, title, doughnutData }) => {
  const options = {
    maintainAspectRatio: false,
    cutout: isHalfDonut ? '50%' : '',
    rotation: isHalfDonut ? -90 : '',
    circumference: isHalfDonut ? 180 : 360,
    plugins: {
      // TODO: Rotate the label if the text is too long
      // datalabels: {
      //   formatter: function(value, context) {
      //     return `${value}%`;
      //   },
      //   font: {
      //     size: 14,
      //     weight: 700
      //   },
      //   labels: {
      //     value: {
      //       color: 'white',
      //     }
      //   }
      // },
      legend: {
        position: 'bottom',
        padding: 80,
        labels: {
          usePointStyle: true,
          title: {
            padding: {
              bottom: 80,
              top: 80,
            },
          },
        },
      },
    }
  }
  
  return (
    <Grid container spacing={2} className="donut-chart" mt={0} ml={0} width="100%">
      <Grid item xs={12} style={{ paddingLeft: 8 }}>
        <Box>
          <Typography color="#140010" fontWeight={700} fontSize="1.2rem">{title}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} height={'250px'}>
        <Doughnut
          id="doughnutChart"
          data={doughnutData}
          // ToDo: Rotate the label if the text is too long
          // plugins={[ChartDataLabels]}
          options={{
            ...options,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DoughnutChart

import React, {useEffect, useState} from 'react'
import {Box, ButtonGroup, Button, Card, Grid} from '@mui/material'
import {
  SouthWest as SouthWestIcon,
  NorthEast as NorthEastIcon,
  AttachMoney as AttachMoneyIcon,
  Receipt as ReceiptIcon,
  OpenInFull as OpenInFullIcon,
  CloseFullscreen as CloseFullscreenIcon,
} from '@mui/icons-material'
import {useHistory} from 'react-router-dom'
import {LoadingBar} from 'views_v2/lib/snippets'
import DetailView from 'components/DetailView'
import {DoughnutChart, LineChart} from './common'
import {useCredential, useDashboard} from 'service/hook'
import ArrayUtils from 'util/array_utils'
import {_getValue} from 'util/string_utils'
import {_getPercentage, _getRandomColor} from 'util/string_utils'
import _ from 'lodash'
import {CardItem, SummaryItem, OtherKPIs} from './snippets'
import {createChart} from 'views_v2/lib/data'
import './scss/parent.scss'

const dateFilter = ['YTD', '1D', '1W', '1M', '3M', '1Y']

const Dashboard = (props) => {
  const history = useHistory()
  const [shopifyBilling, setShopifyBilling] = useState(false)
  const [expand, setExpand] = useState(false)
  const [filter, setFilter] = useState({type: 'YTD'})
  const {credential, onPatchCredential} = useCredential()
  const {dashboard, isLoading, refetch} = useDashboard(filter)

  useEffect(() => {
    const shopifyData = ArrayUtils.getShopifyData(credential?.data)
    if (shopifyData?.length > 0) {
      const {id, value} = shopifyData[0]
      const urlParams = new URLSearchParams(history?.location?.search)
      const params = Object.fromEntries(urlParams)

      if (params?.hasOwnProperty('charge_id') && !shopifyBilling && !value?.shopify_billing_status) {
        onPatchCredential({id, input: {value: {shopify_billing_status: true}}})
        setShopifyBilling(true)
      }
    }
  }, [credential])

  useEffect(() => {
    refetch()
  }, [filter])

  if (isLoading) {
    return <LoadingBar />
  }

  const onExpand = () => {
    setExpand(!expand)
  }

  const onChangeTab = (type) => {
    if (filter?.type !== type) {
      setFilter({type})
    }
  }

  const expandIcon = expand ? <CloseFullscreenIcon /> : <OpenInFullIcon />
  const {
    averageGross,
    averageTransaction,
    totalSales,
    totalRevenue,
    totalCost,
    totalRealizedProfit,
    getValueOfMetric,
    inventoryBreakdown,
    totalSalesBreakDownByBrand,
    totalSalesBreakDownByPlatform,
    sellThroughRate,
  } = dashboard || {}

  return (
    <div className="p-detail-flex dashboard" style={{background: 'white'}}>
      <DetailView.Panel scrollable>
        <Box>
          <ButtonGroup
            className="dashboard-year-group"
            color="secondary"
            aria-label="medium secondary button group"
          >
            {dateFilter.map((d) => {
              const variant = d === filter?.type ? 'contained' : 'outlined'
              return (
                <Button key={d} {...{variant}} onClick={() => onChangeTab(d)}>
                  {d}
                </Button>
              )
            })}
          </ButtonGroup>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 2,
                bgcolor: 'background.default',
                display: 'grid',
                gridTemplateColumns: 'auto auto auto auto',
                gap: 2,
              }}
            >
              <SummaryItem
                title="Total Sales"
                tooltipTitle="The number of sales you have made in this timeframe."
                icon={<ReceiptIcon />}
                value={_getValue(totalSales)}
              />
              <SummaryItem
                title="Total Revenue"
                tooltipTitle="The sum of the Actual Returns of all items sold in this timeframe."
                icon={<NorthEastIcon />}
                value={_getValue(totalRevenue)}
                isMoney
              />
              <SummaryItem
                title="Total Cost"
                tooltipTitle="The sum of the Buy Prices of all items that were created in that timeframe."
                icon={<SouthWestIcon />}
                value={_getValue(totalCost)}
                isMoney
              />
              <SummaryItem
                title="Realized Profit"
                tooltipTitle="The sum of the Profits of all items that sold in this timeframe."
                icon={<AttachMoneyIcon />}
                value={_getValue(totalRealizedProfit)}
                isMoney
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{background: 'white'}}>
          <Grid item xs={expand ? 12 : 8} sx={{pt: 2, pb: 2, pl: 2, pr: expand ? 2 : 0}}>
            <Box height="100%">
              <LineChart
                {...{expandIcon, onExpand}}
                title="Value of Inventory"
                data={createChart(getValueOfMetric, true)}
              />
            </Box>
          </Grid>
          <Grid item xs={!expand && 4} sx={{p: 2}}>
            {!expand && (
              <React.Fragment>
                <CardItem
                  title="Inventory Sell Through Rate"
                  tooltipTitle="(Number of sales that occurred in the period / Number of items created in that time period) * 100"
                  x={sellThroughRate?.soldUnits || 0}
                  y={sellThroughRate?.addedUnits || 0}
                />
                <OtherKPIs title="Other KPIs" {...{averageGross, averageTransaction}} />
                <Card className="dashboard-card-big" sx={{mt: '15px'}}>
                  <DoughnutChart
                    isHalfDonut
                    title="Inventory Breakdown"
                    doughnutData={createChart(inventoryBreakdown)}
                  />
                </Card>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        <Grid container bgcolor="white">
          <Grid item xs={6}>
            <Box
              sx={{
                p: 2,
                bgcolor: 'background.default',
                display: 'grid',
                gridTemplateColumns: 'auto auto auto',
              }}
            >
              <Card className="dashboard-card-big">
                <DoughnutChart
                  title="Sales Breakdown by Brand"
                  doughnutData={createChart(totalSalesBreakDownByBrand)}
                />
              </Card>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                p: 2,
                bgcolor: 'background.default',
                display: 'grid',
                gridTemplateColumns: 'auto auto auto',
              }}
            >
              <Card className="dashboard-card-big">
                <DoughnutChart
                  title="Sales Breakdown by Platform"
                  doughnutData={createChart(totalSalesBreakDownByPlatform)}
                />
              </Card>
            </Box>
          </Grid>
        </Grid>
      </DetailView.Panel>
    </div>
  )
}

export default Dashboard

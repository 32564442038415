import React, {useState, useEffect} from 'react'
import {Box, Stack, Typography} from '@mui/material'
import {isEmpty} from 'lodash'

import {useGlobalStore} from 'provider/global_store/hook'
import {useFees} from 'service/hook'
import CopytCard from 'components/CopytCard'
import {CopytButton, CopytSwitch, FieldGroup} from 'views_v2/lib/snippets'
import EmptyData from 'views_v2/lib/components/EmptyData'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import {checkObjIfEmpty} from 'util/string_utils'
import MinimumInputFee from '../../component/minimum_fee'
import {AccountSettingsModalType} from '../../AccountSettingsModals'
import {CopytBtn} from './ProductTemplate'

const FeeSettings = (p) => {
  const {userRefech} = useGlobalStore()
  const {onSetFee} = p
  const {status, fees, addFee, setDefault, updateFee} = useFees({onSuccessUpdate: userRefech})
  const [showAll, setShowAll] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [isCustomPayout, setIsCustomPayout] = useState(false)
  const [value, setValue] = useState({
    defaultConsignmentFee: '',
  })
  const [errorMessage, setErrorMessage] = useState()
  const [noMinFee, setNoMinFee] = useState(false)
  const defaultValue = fees?.filter((item) => item?.name?.toLowerCase() === 'default')

  useEffect(() => {
    if (status === 'success') {
      if (!isEmpty(defaultValue)) {
        setValue({
          defaultConsignmentFee: defaultValue[0]?.value,
        })
        setIsCustomPayout(defaultValue[0]?.customPayout)
      }
    }
  }, [status, fees])

  const handleFeeSettingsData = (e) => {
    setValue({
      ...value,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  const updateFees = () => {
    // Segment Track call - Fee Settings Update
    if (window.analytics) {
      window.analytics.track('FEE_SETTINGS_UPDATE_CLICKED')
    }

    let formError = null
    if (checkObjIfEmpty(value) && !isCustomPayout) {
      formError = 'is required'
    }

    setErrorMessage(formError)

    if (!formError) {
      if (!isEmpty(defaultValue)) {
        const fee = defaultValue[0]

        if (value.defaultConsignmentFee) {
          updateFee({
            name: fee.name,
            id: fee.id,
            fee: value.defaultConsignmentFee,
            customPayout: isCustomPayout,
          })
        } else {
          addFee({name: 'Default', value: value.defaultConsignmentFee, customPayout: isCustomPayout})
          setDefault({id: fee.id, customPayout: isCustomPayout})
        }
      }
    }
  }

  const renderNonDefault = () => {
    if (Array.isArray(fees) && !isEmpty(fees)) {
      const arr = fees?.filter((f) => f?.name?.toLowerCase() !== 'default')
      if (arr?.length > 0) {
        return (
          <Stack direction="row" flexWrap="wrap" justifyContent="flex-start" gap={1}>
            {arr.map((f, i) => (
              <Box
                key={i}
                onClick={() => onSetFee(f)}
                minWidth="80px"
                border="1px solid var(--success)"
                padding={1}
                borderRadius={1}
                textAlign="center"
                sx={{
                  '&:hover': {
                    backgroundColor: 'var(--lighter)',
                    cursor: 'pointer',
                  },
                }}
              >
                <Typography>{`${f.name}: ${f.value}%`}</Typography>
              </Box>
            ))}
          </Stack>
        )
      } else {
        return <EmptyData title="Default fee is already set up. Feel free to add more." />
      }
    }
    return <EmptyData title="No Data Available." />
  }

  return (
    <CopytCard className="copyt-card">
      <CopytCard.Header style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <CopytCard.Text tag="div">Fee Settings</CopytCard.Text>
        <Box display="flex" gap={2} alignItems="center" justifyContent="flex-end" width="100%">
          <CopytButton
            variant="outline"
            color="primary"
            onClick={() => setShowAll(!showAll)}
            style={{lineHeight: '16px', minHeight: 35}}
          >
            {showAll ? 'Update Fee' : 'Show All'}
          </CopytButton>
          <CopytButton
            variant="outline"
            color="primary"
            onClick={() => onSetFee(AccountSettingsModalType.NEW_FEE)}
            style={{lineHeight: '16px', minHeight: 35}}
          >
            Add New
          </CopytButton>
        </Box>
      </CopytCard.Header>
      <CopytCard.Body>
        {!showAll ? (
          <>
            <MinimumInputFee {...{setNoMinFee, noMinFee, setEnabled}} disabled={isCustomPayout} />
            <FieldGroup
              sLabel="Default Consignment Fee (%)"
              disabled={defaultValue?.length === 0 || isCustomPayout}
              hasError={errorMessage}
              errorMessage={`Consignment Fee ${errorMessage}`}
              name="defaultConsignmentFee"
              type="number"
              onKeyPress={(e) => onKeyPress(e, 'e')}
              placeholder={
                defaultValue?.length === 0 ? 'Please add default consignment fee' : 'Percentage (%)'
              }
              onChange={handleFeeSettingsData}
              value={value.defaultConsignmentFee}
            />
            <CopytSwitch
              _textEnd={{color: 'var(--success) !important', fontSize: '1rem', fontWeight: 600}}
              labelEnd="Custom Payouts"
              onChange={() => setIsCustomPayout(!isCustomPayout)}
              checked={isCustomPayout}
            />
            <Box gap={1} display="flex" justifyContent="flex-end">
              <CopytBtn
                disabled={
                  !enabled &&
                  value.defaultConsignmentFee === (defaultValue.length > 0 && defaultValue[0].value) &&
                  !isCustomPayout
                }
                onClick={updateFees}
                variant="contained"
                color="primary"
              >
                Save
              </CopytBtn>
            </Box>
          </>
        ) : (
          renderNonDefault()
        )}
      </CopytCard.Body>
    </CopytCard>
  )
}

export default FeeSettings

import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {useQueryClient} from 'react-query'
import {debounce, orderBy} from 'lodash'
import {Box, InputLabel, MenuItem, FormControl, Select, Tooltip} from '@mui/material'
import {DataGrid} from '@mui/x-data-grid'

import {useGlobalStore} from 'provider/global_store/hook'
import DetailView from 'components/DetailView'
import {ModalType, PaymentType, ToastType, RedirectAction} from 'enums'
import {useOrders, useExport} from 'service/hook'
import DateUtils from 'util/date_utils'
import {getMinimumFee} from 'util/model'
import {getPlatformName, getConsignEmail, getLedger} from 'util/model/sold'
import {getCurrency} from 'util/model/setting'
import {urlWithoutParam, getParams} from 'util/window_utils'
import {CopytButton, TooltipSnippets, LoadingBar, SearchToolbar} from 'views_v2/lib/snippets'
import OrdersModals from './OrdersModals'
import mw from './middleware'
import {EmptyInventory} from '../Inventory/snippets'
import InventoryToast from '../Inventory/components/InventoryToast'
import './scss/parent.scss'
import {getConsignmentPayout} from 'util/model/consignment'

const WAIT_INTERVAL = 1000

const FilterType = {
  ALL: 'all',
  CONSIGN: 'consign',
  STORE: 'owned',
  DATE_RANGE: 'date_range',
}

const MenuProps = {
  MenuListProps: {
    style: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}

const Orders = () => {
  const history = useHistory()
  const [queries, setQueries] = useState({})
  const [filter, setFilter] = useState({
    skip: 0,
    take: 100,
    consignmentType: FilterType.ALL,
    page: 0,
  })
  const [orderSearchText, setOrderSearchText] = useState('')
  const queryClient = useQueryClient()
  const {user} = useGlobalStore()
  const {
    data: orderData,
    isLoading,
    isFetching,
    refetchOrders,
    isUpdateBatchPaymentsSuccess,
    isCheckoutAllSuccess,
    orders,
  } = useOrders(filter, queries, orderSearchText)

  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [selectionModel, setSelectionModel] = useState([])
  const [paymentType, setPaymentType] = useState("")
  const [otherPaymentType, setOtherPaymentType] = useState(null)
  const [date, setDate] = useState({startDate: null, endDate: null})
  const minFee = getMinimumFee(user)
  const currency = getCurrency()
  const {isEnterprise} = useGlobalStore()
  const timerRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(filter?.page)
  const [mount, setMount] = useState(false)
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const {isErrorExport, isSuccessExport, invalidateExportQueries, refetchExport} = useExport({
    filter: {...filter, take: orderData?.total || 10000, limit: orderData?.total || 10000},
    queries: queries,
    exportType: 'orders',
  })

  const columns = [
    {
      field: 'name',
      headerName: 'Platforms',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return getPlatformName(params.row)
      },
    },
    {
      field: 'itemName',
      headerName: 'Item Name',
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (params) => {
        const {product} = params?.row?.listing || {}
        return product?.title
      },
      renderCell: (params) => {
        const {product} = params?.row?.listing || {}
        const title = product?.title
        return (
          <Tooltip {...{title}}>
            <span>{title}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'size',
      headerName: 'Size',
      headerClassName: 'super-app-theme--header',
      flex: 0.7,
      minWidth: 70,
      valueGetter: (params) => {
        const {product} = params?.row?.listing || {}
        return product?.size
      },
    },
    {
      field: 'condition',
      headerName: 'Condition',
      headerClassName: 'super-app-theme--header',
      flex: 0.7,
      minWidth: 50,
      valueGetter: (params) => {
        const {product} = params?.row?.listing || {}
        return product?.condition
      },
    },
    {
      field: 'sale',
      headerName: 'Sale',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return `${currency.format(params.row?.price)}`
      },
    },
    {
      field: 'listing',
      headerName: 'Payout',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        const payout = getConsignmentPayout(params.row)
        const title = mw.getConsigner(params?.row)
        if (title === 'Store Owned') return ''

        if (isNaN(payout)) return '--'
        return `${currency.format(payout)}`
      },
    },
    {
      field: 'consignee',
      headerName: 'Consignor',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const title = mw.getConsigner(params?.row)
        return <TooltipSnippets {...{title}} />
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${DateUtils.formatTo(DateUtils.formatToLocalDate(params?.row?.updatedAt), 'MMM DD, YYYY')}`,
    },
  ]

  useEffect(() => {
    if (!mount) {
      const params = getParams(history)

      if (params?.hasOwnProperty('page')) {
        const pageNumber = Number(params?.page)
        if (pageNumber !== filter?.page && pageNumber > 0) {
          setFilter((f) => ({...f, page: pageNumber, skip: pageNumber * f.take}))
        }
      }
      setMount(true)
    }
  }, [mount])

  useEffect(() => {
    if (queries?.hasOwnProperty('search') || queries?.hasOwnProperty('startDate')) {
      refetchOrders()
    }
  }, [queries])

  useEffect(() => {
    if (isUpdateBatchPaymentsSuccess) {
      queryClient.invalidateQueries('orders')
    }
  }, [isUpdateBatchPaymentsSuccess])

  useEffect(() => {
    if (isCheckoutAllSuccess) {
      queryClient.invalidateQueries('orders')
    }
  }, [isCheckoutAllSuccess])

  const onSearch = useCallback(
    debounce((searchKey) => {
      setOrderSearchText(searchKey)
    }, 800),
    [],
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}

    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }
    setOrderSearchText(params[RedirectAction.SEARCH]?.split(' ').join('+'))
    searchParams.delete(RedirectAction.SEARCH)

    const newUrl = window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState({}, '', newUrl)
  }, [])

  useEffect(() => {
    return () => {
      if (timerRef?.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    setCurrentPage(currentPage)
    onPageChange(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (currentPage !== filter?.page) {
      setCurrentPage(filter?.page)
      onPageChange(filter?.page)
    }
  }, [filter?.page])

  const onPageChange = (newPage) => {
    if (timerRef?.current) {
      clearTimeout(timerRef?.current)
    }

    timerRef.current = setTimeout(() => {
      setFilter((f) => ({...f, page: newPage, skip: newPage * f.take}))
      const newUrl = urlWithoutParam(newPage)
      window.history.pushState({path: newUrl}, '', newUrl)
    }, WAIT_INTERVAL)
  }

  // const onSearch = (searchKey) => setQueries({ search: searchKey })

  const handleChange = (event) => {
    setFilter((s) => {
      let reset = {}
      if (s.consignmentType !== event.target.value) {
        reset = {
          page: 0,
          skip: 0,
          take: 100,
        }
      }
      return {...s, ...reset, consignmentType: event.target.value}
    })
  }

  const data = useMemo(() => {
    return selectionModel.map((i) => {
      return {...orders.find((x) => x.id === i), paymentType}
    })
  }, [orders, selectionModel, paymentType])

  const handleDateRange = (date) => {
    const dates = DateUtils.convertDatesToUTC(date.startDate, date.endDate)

    setQueries({
      ...queries,
      ...dates,
    })

    setDate(date)
    setModalType(ModalType.UNDEFINED)
  }

  const Toolbar = () => {
    return (
      <SearchToolbar
        hasCustomDate
        onCustomDate={() => setModalType(ModalType.DATE_PICKER)}
        onExport={() => refetchExport()}
        onSearch={onSearch}
        search={{
          autoFocus: modalType === ModalType.UNDEFINED,
          onSearch: onSearch,
          value: orderSearchText,
          disabled: isFetching,
        }}
      />
    )
  }

  useEffect(() => {
    if (isSuccessExport) {
      setToastType(ToastType.EXPORTED)
    } else if (isErrorExport) {
      setToastType(ToastType.ERROR)
    }
    invalidateExportQueries()
  }, [isErrorExport, isSuccessExport])

  if (isLoading) return <LoadingBar />

  let arr = orders

  const CustomNoRowsOverlay = () => {
    return <EmptyInventory {...{handleChange}} type="sold" tabSelection="sold" />
  }

  return (
    <div className="p-detail-flex orders full-height">
      <DetailView.Panel>
        <Box
          sx={{
            height: '85%',
            width: 1,
            '& .super-app-theme--header': {
              fontWeight: 'bolder',
            },
          }}
        >
          <Box display="flex" flexDirection="row-reverse">
            <FormControl sx={{m: 1, minWidth: 200}} size="small">
              <InputLabel>Filter By</InputLabel>
              <Select
                className="orders-filter-by"
                value={filter?.consignmentType || 'all'}
                label="Filter By"
                onChange={handleChange}
                MenuProps={MenuProps}
              >
                <MenuItem value={FilterType.ALL}>All</MenuItem>
                <MenuItem value={FilterType.CONSIGN}>Consignor Owned</MenuItem>
                <MenuItem value={FilterType.STORE}>Store Owned</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{m: 1}} size="small">
              <CopytButton
                variant="contained"
                color="primary"
                disabled={selectionModel.length === 0}
                onClick={() => setModalType(ModalType.CREATE_BATCH)}
                style={{
                  borderRadius: '6px',
                  padding: '12px 16px',
                  height: '40px',
                }}
              >
                Create Payout
              </CopytButton>
            </FormControl>
          </Box>
          <DataGrid
            rows={orderBy(arr, [(item) => item.updatedAt], ['desc'])}
            columns={columns}
            loading={isLoading || isFetching}
            isRowSelectable={(params) => {
              return !!getConsignEmail(params?.row) && !getLedger(params.row)
            }}
            checkboxSelection={filter.consignmentType !== FilterType.STORE}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
            selectionModel={selectionModel}
            components={{Toolbar: Toolbar, NoRowsOverlay: CustomNoRowsOverlay}}
            componentsProps={{
              toolbar: {
                onSearch,
              },
            }}
            onSelectionModelChange={(ids) => setSelectionModel(ids)}
            onFilterModelChange={(s) => onSearch(s?.quickFilterValues[0])}
            pageSize={filter.take}
            pagination
            onPageChange={(newPage) => setCurrentPage(newPage)}
            page={currentPage}
            paginationMode="server"
            rowCount={orderData?.total}
            rowsPerPageOptions={[filter.take]}
            onSortModelChange={(newSort) => {
              const {field, sort} = newSort[0] || {}
              if (queries?.hasOwnProperty('search')) delete queries?.search
              setQueries({
                sortBy: field,
                sortDirection: sort,
              })
            }}
          />
        </Box>
        <OrdersModals
          {...{
            handleDateRange,
            data,
            date,
            modalType,
            setModalType,
            paymentType,
            otherPaymentType,
            setOtherPaymentType,
            setPaymentType,
            setSelectionModel,
            onSuccess: refetchOrders,
          }}
        />
        {toastType > ToastType.UNDEFINED && <InventoryToast {...{toastType, setToastType}} />}
      </DetailView.Panel>
    </div>
  )
}

export default Orders

import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const Toolbar = styled(Box)(({ props }) => ({
  ...props,
  display: "flex",
  flex: "0 1 auto",
  padding: "5px 20px",
  borderBottom: "solid 1px #dddddd",
  backgroundColor: "#ffffff",
  height: "50px",
  alignItems: "center"
}))

const CopytToolbar = (props) => {
  return (
    <Toolbar {...props.style}>
      {props.children}
    </Toolbar>
  );
};

export default CopytToolbar;

import React, {useMemo, useEffect, useState} from 'react'
import {Box, Typography, Stack, Radio, FormControl, FormControlLabel, RadioGroup} from '@mui/material'
import {pink} from '@mui/material/colors'
import {chain, get, isEmpty} from 'lodash'
import Select from 'react-select'

import {ModalType} from 'enums'
import {_replaceWildCards} from 'util/string_utils'
import {CopytLabel, CopytStyledButton} from 'views_v2/lib/snippets'
import {useConsignmentShops} from 'service/hook'
import EmptyData from 'views/components/empty_data'

const MdlChooseConsignee = (props) => {
  const {
    approvedPlatforms,
    setModalType,
    consignee,
    setConsignee,
    setSelectedLocation,
    selectedLocation,
  } = props

  const {storeLocations} = useConsignmentShops(
    {
      options: {
        enabled: true,
        staleTime: Infinity,
      },
      queries: {},
    },
    consignee?.value,
  )
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if (!isEmpty(storeLocations)) {
      setOptions(
        storeLocations.map((storeLocation) => ({
          label: storeLocation.name,
          value: storeLocation.id,
        })),
      )
      setSelectedLocation({
        label: storeLocations[0].name,
        value: storeLocations[0].id,
      })
      setConsignee({
        ...consignee,
        location: storeLocations[0].name,
      })
      setIsLoading(false)
    } else {
      setSelectedLocation(null)
      setConsignee({
        ...consignee,
        location: 'default',
      })
      setIsLoading(false)
    }
  }, [storeLocations])

  const approvedPlatformsArray = useMemo(
    () =>
      chain(approvedPlatforms)
        .filter((item) => get(item, 'platformName'))
        .map((item) => ({
          label: get(item, 'platformName'),
          value: get(item, 'enterpriseId'),
        }))
        .uniqBy('label')
        .value(),
    [approvedPlatforms],
  )

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Choose Consignee
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body" padding={0} my={2}>
        {isEmpty(approvedPlatformsArray) ? (
          <Box mb={2}>
            <EmptyData title="Sorry! We haven't found any APPROVED items." />
          </Box>
        ) : (
          <Stack px={2} spacing={2} mb={2}>
            <CopytLabel sx={{fontSize: '0.9rem'}}>Please select a store to continue</CopytLabel>
            <Stack direction="row" spacing={1} pl={2}>
              <Stack spacing={1}>
                <RadioGroup>
                  {approvedPlatformsArray?.map((s, i) => (
                    <FormControlLabel
                      key={`control-label-shop-${i}`}
                      value={s?.value}
                      control={
                        <Radio
                          size="small"
                          sx={{
                            marginRight: '10px !important',
                            '&.Mui-checked': {
                              color: pink[600],
                            },
                          }}
                          key={`consignment-shop-${i}`}
                          onChange={() => setConsignee({...consignee, ...s})}
                        />
                      }
                      label={s?.label}
                      sx={{
                        '& .MuiTypography-root': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          maxWidth: consignee?.value ? '250px' : '100%',
                          fontSize: '0.9rem',
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              </Stack>
              {consignee?.value && (
                <FormControl fullWidth>
                  <CopytLabel>Store Location</CopytLabel>
                  <Select
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    className="react-select info"
                    classNamePrefix="react-select"
                    onChange={(e) => {
                      setSelectedLocation(e)
                      setConsignee({...consignee, location: e?.label})
                    }}
                    options={options}
                    value={selectedLocation}
                  />
                </FormControl>
              )}
            </Stack>
          </Stack>
        )}

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            disabled={!consignee?.value}
            variant="contained"
            color="primary"
            onClick={() => setModalType(ModalType.SELECT_APPROVED_ITEMS)}
          >
            Continue
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlChooseConsignee
